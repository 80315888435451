import PropTypes from "prop-types";
import React, { useEffect, useState,useCallback } from "react";

import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction,
} from '../actions/layout';

// Layout Related Components
import Header from 'components/navs/Header';
import Sidebar from 'components/navs/sidebar';
// import Footer from 'components/navs/Footer';
import RightSidebar from 'components/CommonForBoth/RightSidebar';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'helpers/utils';
import _ from 'lodash';

const AdminLayout = (props) => {
  const dispatch = useDispatch();

  const {
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    leftSideBarTheme,
  } = useSelector((state) => ({
    isPreloader: state.Layout.isPreloader,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }));

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [isOpen, setOpen] = useState(true);

  const websiteGuard = useCallback(() => {
    if (_.isEmpty(localStorage.getItem('authorization'))) logout();
  }, []);

  useEffect(() => {
    websiteGuard();
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout('vertical'));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
    }
  }, [leftSideBarThemeImage, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);
  
  const getTitle = () => {
    let pathname = props?.location?.pathname?.split('/')
    return pathname[2] || ''
  }

  return (
    <React.Fragment>
      <div id='layout-wrapper'>
        <Header title={getTitle()} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
          {...props}
        />
        <div className='main-content' >{props.children}</div>
      </div>
    </React.Fragment>
  );
};

AdminLayout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  // children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export { AdminLayout };
