import { admin } from 'service/actionType'
// import { inventory, main } from '../../service/actionType'
const initialState = {
    adminList: {
        list: [],
        pageMetaData: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        }
    }
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case admin.ADMIN.GET_ADMINS_LIST:
            return {
                ...state,
                adminList: {
                    list: payload.list,
                    pageMetaData: payload.pageMetaData
                }
            }
        default:
            return state
    }

}