
import React, { useEffect, useState } from "react";
import { Badge, Collapse } from "reactstrap";
import { toggleRootClassName } from 'helpers/utils'
import { getLinksBasedOnRoleId } from 'helpers/roleHandler'
import {Toast} from "helpers/Toast";
import SimpleBar from "simplebar-react";
import "./sidebar.scss";
import logoSm from "../../assets/images/logo.png";
import jwt from 'jwt-decode';

let prefix = "/staff"


const Sidebar = ({ location, history }) => {
  const [filteredLinks, setFilteredLinks] = useState([])
  const { innerWidth: width } = window;

  const redirectTo = (pathName) => {
    if(width <= 1199) {
      toggleRootClassName("open-sidebar")
    }
    history.push(prefix + pathName)
  }

  
  useEffect(() => {
    // checkforsidebartoggle()
    let authToken = localStorage.getItem('authorization')
    let decodedUser = jwt(authToken)
    let newfilteredLinks = getLinksBasedOnRoleId(decodedUser?.role)
    setFilteredLinks(newfilteredLinks)
  })


  const toggleActiveSubMenu = (index = null, data = null) => {
    if(index != null && data != null){
      filteredLinks.map((link, i) => {
        if(index === i ){  link.showSubContent = !data.showSubContent }
      })
    } else {
      filteredLinks.map((link, i) => {
        if(link.showSubContent != undefined) link.showSubContent = false
      })
    }
    setFilteredLinks([].concat(filteredLinks))
  }

  const handleLinkClick = (data, index) => {
    var body = document.body;
    if(body.classList.contains('sidebar-mini')){
      if (data.showSubContent == undefined){
        redirectTo(data.routeName)
        toggleActiveSubMenu(null, null)
      }
    } else {
      if (data.showSubContent != undefined){
        toggleActiveSubMenu(index, data)
      } else { 
        redirectTo(data.routeName)
        toggleActiveSubMenu(null, null)
      }
    }
  }
  
  
  const modelAccepted = () =>{
    localStorage.clear();
    sessionStorage.clear();
    history.replace("/auth/staff/login");
    Toast({ type: 'info', message: 'Logged out successfully!'})
  }

  return (
    <>
 
   <div className="side-nav" id="sidebar-menu">
 
        <aside className="w-100 d-flex flex-column">
          <div className="sidebar_logo">
            <div className="logo">
              <img src={logoSm} alt="logo" />
            </div>
            <div className="logo-sm">
              <img src={logoSm} alt="logo" />
            </div>
          </div>
      
          <ul className="w-100 list-unstyled staff-sidebar custom-scrollbar flex-grow-1">
            {filteredLinks.map((data, i) => (
              <li
                id={data.togglerName}
                className={`nav-list-menu ${data.className} ${
                  location.pathname.includes(prefix + data?.routeName) ? "active" : ''
                  } `}
              >
                {data.subContent !== undefined && data.subContent.length > 0
                  ?
                  // with subcontent
                  <>
                    <div
                      onClick={() => handleLinkClick(data, i)}
                      className={`nav-list-wrapper ${location.pathname.includes(prefix + data.routeName) ? "text-white br-0" : "br-0"}`}
                      data-toggle={data.showSubContent}
                    >
                      <div className="nav-view-icon">
                        {/* <img src={data.icon} /> */}
                        {data.icon}
                      </div>
                      <div className="nav-view-content">
                        <h5 className="name">{data.name}</h5>
                      </div>
                      {
                        data.subContent ? <i className={`ml-3 fa arrow fa-chevron-${data.showSubContent ? "up" : "down"} small`}></i> : ''
                      }
                    </div>
                    <Collapse isOpen={data.showSubContent} id="collapse">
                      <ul className={`list-unstyled sub-content `}>
                        {data.subContent.map((subData) => {
                          return (
                            <li
                              className={` nav-list-menu`}
                              onClick={() => redirectTo(subData.routeName)}
                            >
                                <div className={`${location.pathname.includes(prefix + subData.togglerName) ? 'active' : ''} nav-list-wrapper`}>
                                  {/* <div className="nav-view-icon">
                                    <i className={subData.iconClass}></i>
                                  </div> */}
                                  <div className="nav-view-content">
                                    <h5 className="name">
                                      {subData.name}
                                    </h5>
                                  </div>
                                </div>
                            </li>
                          );
                        })}
                      </ul>
                    </Collapse>
                  </>
                  :
                  // without subcontent
                  <div
                    onClick={() => handleLinkClick(data)}
                    className={`nav-list-wrapper ${location.pathname.includes(prefix + data.routeName) ? "text-white br-0" : "br-0"}`}
                  >
                    <div className="nav-view-icon">
                      {/* <img src={data.icon} /> */}
                      {data.icon}

                    </div>
                    <div className="nav-view-content">
                      <h5 className="name">{data.name}</h5>
                    </div>
                  </div>
                }
              </li>
            ))}
            <div className="modal-backdrop  show d-none" onClick={() => toggleRootClassName("open-sidebar")}></div>
          </ul>
         
         
        </aside>
      </div>
  
    
      <div className="modal-backdrop show d-none" onClick={() => toggleRootClassName("open-sidebar")}></div>
    </>
  );
}
export default Sidebar