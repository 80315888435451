import React from 'react';

const createApplicationBtn = (props) => {
    const { btnName, onCreateApplicationBtnClick, btnClassname, disableCreateApp} = props
    return (
        <>
            {disableCreateApp ?
                <div className='disable_create_button'>
                    <i className="icon-plus small" aria-hidden="true"></i>
                    <span className="ml-2">{btnName}</span>
                </div>
                :
                <div
                    className={`create_button ${btnClassname}`}
                    onClick={onCreateApplicationBtnClick}
                >   
                    <i className="icon-plus small" aria-hidden="true"></i>
                    <span className="ml-2">{btnName}</span>
                </div>
            }
        </>
     );
}

export default createApplicationBtn;

createApplicationBtn.defaultProps = {
    onCreateApplicationBtnClick : () => {}
}