export const ORDER_ASSIGNED_TABS = [
  {
      label: 'Open Orders',
      value: [1],
      icon: 'icon-doc-color'
  },
  {
      label: 'Delivered Orders',
      value: [2],
     
  },
]