import React, { useEffect, useState } from "react";
import {
  Container,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { CustomerPaymentTableList, WordsColumn } from "helpers/tableDataMapper";
import { useSelector, useDispatch } from "react-redux";

const Payments = (props) => {
    const [rows] = useState([
        {
            customerPaymentId: '1303',
            userMobileNumber: '842822990',
            userName: 'Eshwar',
            userId: '12',
            orderId: '1002',
            status: 'PAID',
            totalAmount: '423',
            paymentType: 'Cash',
            createdAt: '2023-01-31T14:14:46.996Z',
            updatedAt: '2023-01-31T14:14:46.996Z'
        },
        {
            customerPaymentId: '1303',
            userMobileNumber: '842822990',
            userName: 'Eshwar',
            userId: '12',
            orderId: '1002',
            status: 'PAID',
            totalAmount: '423',
            paymentType: 'Cash',
            createdAt: '2023-01-31T14:14:46.996Z',
            updatedAt: '2023-01-31T14:14:46.996Z'
        },
        {
            customerPaymentId: '1303',
            userMobileNumber: '842822990',
            userName: 'Eshwar',
            userId: '12',
            orderId: '1002',
            status: 'PAID',
            totalAmount: '423',
            paymentType: 'Cash',
            createdAt: '2023-01-31T14:14:46.996Z',
            updatedAt: '2023-01-31T14:14:46.996Z'
        },
        {
            customerPaymentId: '1303',
            userMobileNumber: '842822990',
            userName: 'Eshwar',
            userId: '12',
            orderId: '1002',
            status: 'NOTPAID',
            totalAmount: '423',
            paymentType: 'Cash',
            createdAt: '2023-01-31T14:14:46.996Z',
            updatedAt: '2023-01-31T14:14:46.996Z'
        },
    ])
    
    return (
        <div className="page-content">
            <CustomBootstrapTable 
                rows = {rows}
                columns = {CustomerPaymentTableList}
                onClick = {null}
                isLoading={false}
                keyField = "orderId"
                showFilter={false}
                showDateFilter={false}
            />
        </div>
    )
}

export { Payments }