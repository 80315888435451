import React, { useEffect, useState } from "react";
import {
    Container,
    Card,
    CardBody,
    Row,
    Col,
} from "reactstrap";
import { useSelector } from 'react-redux';
import { BiBookAlt, BiUser } from "react-icons/bi";
import {MdProductionQuantityLimits,MdPayments} from 'react-icons/md'
import {BsFillBookmarkFill} from 'react-icons/bs'
import {RiAdminFill} from 'react-icons/ri'
import{FaTable} from 'react-icons/fa'
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { TopCustomersTable } from 'helpers/tableDataMapper'
import { OverallOrdersGraph } from "components/graphs/OverallOrdersGraph";
import Header from 'components/navs/Header'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dashboardAction from 'actions/dashboard';
import moment from 'moment';

const DashboardFunction = (props) => {

    useEffect(() => {
        fetchAll()
    }, [])

    const { topCustomers, dashboardCountsAndTopCustomers} = useSelector((state) => ({
        topCustomers: state.dashboard.dashboardCountsAndTopCustomers?.topCustomers,
        dashboardCountsAndTopCustomers: state.dashboard.dashboardCountsAndTopCustomers
    }));

    const fetchAll = async () => {
        fetchDashboardData()
    }

    const fetchDashboardData = () => {
        props.getDashboardCountsAndTopCustomers()

        let yearOrderStatsPayload = {
            year: parseInt(moment().format('yyyy'))
        }

        props.getYearlyOrderStats(yearOrderStatsPayload)


    }

    const onYearChange = async (value) => {
        let queryPayload = {
            year: value
        }
        await props.getYearlyOrderStats(queryPayload)
    }
    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 998) {
            body.classList.remove("sidebar-enable");
        } 
    
      }

    return (
        <React.Fragment>
              <div className='page-content'>
                <Container fluid className="p-0 mt-10">
                    <Row>
                        <Col xl="8">
                            <Row>
                                <Col md="4">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">
                                                        Total Orders
                                                    </p>
                                                    <h4 className="mb-0">{dashboardCountsAndTopCustomers?.counts?.totalOrders}</h4>
                                                </div>
                                                <div className="avatar-xs rounded-circle bg-avatar align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-avatar">
                                                        <MdProductionQuantityLimits size={15} />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">
                                                        Delivered   Orders
                                                    </p>
                                                    <h4 className="mb-0">{dashboardCountsAndTopCustomers?.counts?.totalCompletedOrder}</h4>
                                                </div>
                                                <div className="avatar-xs rounded-circle bg-avatar align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-avatar">
                                                        <BiBookAlt size={15} />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">
                                                        Cancelled Orders
                                                    </p>
                                                    <h4 className="mb-0">{dashboardCountsAndTopCustomers?.counts?.totalCancelledOrder}</h4>
                                                </div>
                                                <div className="avatar-xs rounded-circle bg-avatar align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-avatar">
                                                        <BsFillBookmarkFill size={15} />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <OverallOrdersGraph
                                ordersStats={props.dashboard?.yearlyOrdersStats}
                                onYearChange={onYearChange}
                            />
                        </Col>
                        <Col xl="4">
                            <Row>
                                <Col md="6">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">
                                                        Open Orders
                                                    </p>
                                                    <h4 className="mb-0">{dashboardCountsAndTopCustomers?.counts?.totalOpenOrder}</h4>
                                                </div>
                                                <div className="avatar-xs rounded-circle bg-avatar align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-avatar">
                                                        <MdPayments size={15} />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">
                                                        Customers
                                                    </p>
                                                    <h4 className="mb-0">{dashboardCountsAndTopCustomers?.counts?.totalCustomers}</h4>
                                                </div>
                                                <div className="avatar-xs rounded-circle bg-avatar align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-avatar">
                                                        <RiAdminFill size={15} />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">
                                                        Categories
                                                    </p>
                                                    <h4 className="mb-0">{dashboardCountsAndTopCustomers?.counts?.totalCategories}</h4>
                                                </div>
                                                <div className="avatar-xs rounded-circle bg-avatar align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-avatar">
                                                        <FaTable size={15} />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">
                                                        Products
                                                    </p>
                                                    <h4 className="mb-0">{dashboardCountsAndTopCustomers?.counts?.totalProduct}</h4>
                                                </div>
                                                <div className="avatar-xs rounded-circle bg-avatar align-self-center mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-avatar">
                                                        <BiUser size={15} />
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                  
                    <Row>
                        <Col xl="12">
                            <div className="h4 card-title mb-3">Top Customers</div>
                            <CustomBootstrapTable
                                rows={topCustomers}
                                columns={TopCustomersTable}
                                onClick={null}
                                isLoading={false}
                                keyField="orderId"
                                showFilter={false}
                                showDateFilter={false}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = ({ dashboard, loader }) => {
    return {
      dashboard,
      loader,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(dashboardAction, dispatch);
  };
  const Dashboard = connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardFunction);

export { Dashboard }