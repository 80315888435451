export const login = {
    ADMIN_LOGIN: 'AUTH_ADMIN_LOGIN',
}


/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_THEME_IMAGE = "CHANGE_SIDEBAR_THEME_IMAGE"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU"
/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"
export const loaders = {
    IS_NOTIFICATION_DETAIL_FETCHING: 'IS_NOTIFICATION_DETAIL_FETCHING',
    IS_APP_FETCHING : 'IS_CREATE_APP_FETCHING',
    IS_FETCHING_PROFILE_DETAILS: "IS_FETCHING_PROFILE_DETAILS",
    IS_TABLE_DETAIL_FETCHING: "IS_TABLE_DETAIL_FETCHING",
    GET_SELECTED_BOOKING_LOADING: "GET_SELECTED_BOOKING_LOADING",
    TOGGLE_STAFF_DETAILS: "TOGGLE_STAFF_DETAILS",
    IS_SHOP_DETAIL_FETCHING: "IS_SHOP_DETAIL_FETCHING",
    IS_ORDERS_LIST_FETCHING: "IS_ORDERS_LIST_FETCHING",
    IS_COUPON_DETAIL_FETCHING: "IS_COUPON_DETAIL_FETCHING",

    DASHBOARD_COUNTS_FETCHING: "DASHBOARD_COUNTS_FETCHING",
    DASHBOARD_ORDER_STATS_FETCHING: "DASHBOARD_ORDER_STATS_FETCHING",
    DASHBOARD_SHOP_ANALYTICS_FETCHING: 'DASHBOARD_SHOP_ANALYTICS_FETCHING',
    DASHBOARD_TOP_CUSTOMERS_FETCHING: 'DASHBOARD_TOP_CUSTOMERS_FETCHING' 
}

export const profileActionType = {
    UPDATE_PROFILE_DETAILS : 'UPDATE_PROFILE_DETAILS'
}

export const main = {
    MONTHLY_ORDER_STATS: 'MONTHLY_ORDER_STATS',
    GET_CUSTOMER_HISTORY: 'GET_CUSTOMER_HISTORY',
    ORDER_STATS: "ORDER_STATS",
    DASHBOARD_COUNT:"DASHBOARD_COUNT",
    SHOP_ANALYTICS: "SHOP_ANALYTICS",
    TOP_CUSTOMERS: "TOP_CUSTOMERS",
    GET_ALL_DELIVERY_BOYS: "GET_ALL_DELIVERY_BOYS",
    GET_ORDER_DETAIL_PRODUCT_LIST: 'GET_ORDER_DETAIL_PRODUCT_LIST',
    COUPON_LIST: "COUPON_LIST",
    COUPON_FORM_CREATE: "COUPON_FORM_CREATE",
    CITY_TABLE_LIST:"CITY_TABLE_LIST",
    CITY_FORM_CREATE_OR_UPDATE:"CITY_FORM_CREATE_OR_UPDATE",
    LOCATION_FORM_RESET:"LOCATION_FORM_RESET",
    LOCATION_LIST:"LOCATION_LIST",
    BANNER_FORM_CREATE:"BANNER_FORM_CREATE",
    BANNER_TABLE_LIST: "BANNER_TABLE_LIST",
    BANNER_DROPDOWN_LIST:"BANNER_DROPDOWN_LIST",
    GET_NOTIFICATION_DETAIL:  'GET_NOTIFICATION_DETAIL',
    NOTIFICATION_LIST: 'NOTIFICATION_LIST',
    GET_DELIVERY_BOYS_PAYMENT: "GET_DELIVERY_BOYS_PAYMENT",
    GET_CUSTOMER_LIST: "GET_CUSTOMER_LIST",
    RESET_SELECTED_BOOKINGID: "RESET_SELECTED_BOOKINGID",
   
    CATEGORY_DROPDOWN_LIST: "CATEGORY_DROPDOWN_LIST",
    SHOPS_LIST: 'SHOPS_LIST',
    REVENUE_LIST: "REVENUE_LIST",
    PRODUCT_LIST: "PRODUCT_LIST",
    CATEGORY_LIST: "CATEGORY_LIST",
    SHOPS_DROPDOWN_LIST: "SHOPS_DROPDOWN_LIST",
    GET_BOOKING_DETAIL: "GET_BOOKING_DETAIL",
    GET_SHOP_CATEGORY_DETAIL : 'GET_SHOP_CATEGORY_DETAIL',
    GET_PRODUCT_DETAIL : 'GET_PRODUCT_DETAIL',
    GET_EMPLOYEES_LIST : "GET_EMPLOYEES_LIST",
    EMPLOYEE_MASTER_DETAIL: "EMPLOYEE_MASTER_DETAIL",
    GET_EMPLOYEE_DETAIL: "GET_EMPLOYEE_DETAIL",
    BOOKING_DETAIL_BY_ID: "BOOKING_DETAIL_BY_ID",
    SELECTED_BOOKING_ID: "SELECTED_BOOKING_ID",
    GET_SHOP_CATEGORY_PRODUCT_DETAIL: "GET_SHOP_CATEGORY_PRODUCT_DETAIL",
    SHOP_FORM_CREATE_OR_UPDATE: "SHOP_FORM_CREATE_OR_UPDATE",
    SHOP_FORM_RESET: "SHOP_FORM_RESET",
    GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LISt: "GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LIST",
    GET_DELIVERED_LIST: "GET_DELIVERED_LIST",
    GET_CUSTOMER_PAYMENT_LIST: "GET_CUSTOMER_PAYMENT_LIST",
    GET_SHOP_PAYMENT_LIST: "GET_SHOP_PAYMENT_LIST"
}

export const dashboard = {
    TOP_CUSTOMERS_AND_DASHBOARD_COUNTS : "TOP_CUSTOMERS_AND_DASHBOARD_COUNTS",
    YEARLY_ORDER_STATS : "YEARLY_ORDER_STATS"
}
export const category = {
    CATEGORY: {
        IS_CATEGORY_LIST_FETCHING: "IS_CATEGORY_LIST_FETCHING",
        IS_CATEGORY_DETAIL_FETCHING: "IS_CATEGORY_DETAIL_FETCHING",
        
        CATEGORY_LIST: "CATEGORY_LIST"
    },
}
export const product = {
    PRODUCT: {
        IS_PRODUCT_LIST_FETCHING: "IS_PRODUCT_LIST_FETCHING",
        IS_PRODUCT_DETAIL_FETCHING: "IS_PRODUCT_DETAIL_FETCHING",
        
        PRODUCT_LIST: "PRODUCT_LIST"
    },
}
export const admin = {
    ADMIN: {
        IS_USER_LIST_FETCHING: "IS_USER_LIST_FETCHING",
        IS_USER_DETAIL_FETCHING: "IS_USER_DETAIL_FETCHING",
        
        GET_ADMINS_LIST: "GET_ADMINS_LIST",
        ROLES_LIST: "ROLES_LIST",
        
    },
}

export const customer = {
    CUSTOMER: {
        IS_CUSTOMER_LIST_FETCHING:"IS_CUSTOMER_LIST_FETCHING",

        CUSTOMER_LIST: "CUSTOMER_LIST"
    }
}


export const order = {
    ORDER: {
        IS_ORDER_LIST_FETCHING: "IS_ORDER_LIST_FETCHING",
        IS_ORDER_DETAIL_FETCHING: "IS_ORDER_DETAIL_FETCHING",
        IS_DELIVERY_PARTNER_LIST_FETCHING: "IS_DELIVERY_PARTNER_LIST_FETCHING",

        ORDER_LIST: "ORDER_LIST",
        DELIVERY_PARTNER_ASSIGNED_ORDERS: "DELIVERY_PARTNER_ASSIGNED_ORDERS",
        DELIVERY_PARTNER_DELIVERED_ORDERS: "DELIVERY_PARTNER_DELIVERED_ORDERS"
    }
}
export const banner = {
    BANNER: {
        IS_BANNER_LIST_FETCHING: "IS_BANNER_LIST_FETCHING",
        IS_BANNER_DETAIL_FETCHING: "IS_BANNER_DETAIL_FETCHING",
        
        BANNER_LIST: "BANNER_LIST"
    }
}


export const deliveryboy = {
    DELIVERYBOY: {
        IS_ORDER_LIST_FETCHING: "IS_ORDER_LIST_FETCHING",
        DELIVERY_PARTNER_ASSIGNED_ORDERS: "DELIVERY_PARTNER_ASSIGNED_ORDERS",
        DELIVERY_PARTNER_DELIVERED_ORDERS: "DELIVERY_PARTNER_DELIVERED_ORDERS",

        DELIVERED_LIST: "DELIVERED_LIST"
    }
}
