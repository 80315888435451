import React from 'react'

const StaticLayout = ({children}) => {
    return (
        <div className='auth_layout' >
            {children}
        </div>
    )
}

export { StaticLayout }