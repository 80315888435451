import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Label,
} from "reactstrap"
import Dropzone from "react-dropzone"

import { Link } from "react-router-dom"

const FormUpload = (props) => {

  function handleAcceptedFiles(files) {
    props.handleFileUpload(files[0])
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Form>
            <Dropzone
              onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div className="dropzone-previews" id="file-previews">
                        <Card
                          className="shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={"-file"}
                        >
                          <div className="dropzone-image-viewer">
                            <img
                              className="avatar-sm rounded bg-light"
                              alt={"banner"}
                              src={props.preview != '' ? props.preview : require('../../assets/images/placeholder.png')}
                            />
                          </div>
                        </Card>
                  </div>
                  <div
                    className="dz-message needsclick"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div>
                      <i className="display-6 text-muted bx bxs-cloud-upload" />
                    </div>
                    <p>{props.title}</p>
                  </div>
                </div>
              )}
            </Dropzone>

          </Form>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FormUpload
