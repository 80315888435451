import { banner } from 'service/actionType'
import { API } from 'service/api'
import { API_BOOK } from 'service/endpoints'
import { Toast } from 'helpers/Toast'
import _ from 'lodash'


let { BANNER_LIST_API, CREATE_OR_UPDATE_BANNER_API } = API_BOOK.MAIN_APP.BANNER
let {
    IS_BANNER_LIST_FETCHING,
    IS_BANNER_DETAIL_FETCHING,

    BANNER_LIST

} = banner.BANNER

let sampleListData = {
    data: [],
     pageMetaData: {
        count: 0,
        limit: 10,
        page: 1,
        totalPages: 0
    }
}

export const getBannerList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type:  IS_BANNER_LIST_FETCHING, payload:  true })
       
        let requestPayload = {
            ...BANNER_LIST_API,
            params: {
                ...queryPayload
            }
        }
        let response = await API(requestPayload)
        if(response.status == 200 ) {
            dispatch({ type: BANNER_LIST, payload: response.data})
            dispatch({type: IS_BANNER_LIST_FETCHING, payload:false})
        } else {
            dispatch({ type: BANNER_LIST, payload: sampleListData})
            dispatch({type: IS_BANNER_LIST_FETCHING, payload:false})
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message);
    }
}

export const updateBanner = (payload) => async (dispatch, getState) => {
    try {
        dispatch({ type: IS_BANNER_DETAIL_FETCHING, payload: true })
        let requestPayload = {
            ...CREATE_OR_UPDATE_BANNER_API,
            url: `${CREATE_OR_UPDATE_BANNER_API.url}/${payload.id}`,
            method: 'PUT',
            data: {
                bannerUrl: payload.bannerUrl,
                bannerName: payload.bannerName
            }
        }
        let response = await API(requestPayload)
        if (response.status == 201) {
            dispatch({ type: IS_BANNER_DETAIL_FETCHING, payload: false })
        } else {
            Toast('error', response?.data?.message)
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message)
    }
}

export const createBanner = (payload) => async (dispatch, getState) => {
    try {
        dispatch({ type: IS_BANNER_DETAIL_FETCHING, payload: true })
        let requestPayload = {
            ...CREATE_OR_UPDATE_BANNER_API,
            data: {
                bannerUrl: payload.bannerUrl,
                bannerName: payload.bannerName
            }
        }
        let response = await API(requestPayload)
        if (response.status == 201) {
            dispatch({ type: IS_BANNER_DETAIL_FETCHING, payload: false })
        } else {
            Toast('error', response?.data?.message)
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message)
    }
}