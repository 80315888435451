import axios from 'axios';
import config from 'config';

axios.defaults.baseURL = config.api.BASE_URL;

axios.interceptors.request.use(
  async function (config) {
    config.headers['authorization'] = localStorage.getItem('authorization');
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
export { axios };
