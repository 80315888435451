import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import moment from 'moment'
import Pagination from './pagination';
import FormInput from 'components/FormInput';
import _ from 'lodash';
import CreateApplication from 'components/Button/createApplicationBtn'

export const CustomBootstrapTable = ({ 
    
        keyField, 
        onClick,
        rows,
        columns,
        onSelectionOfDate,
        sort, 
        meta,
        isLoading, 
        onCreateRedirect,
        history,
        triggerPopup, 
        createBtnLabel,
        filterList,
        getApplicationDetails,
        showCreateBtn,
        showDateFilter,
        showSearch,
        pageMetaData
    }, props) => {

    const [value, setDate] = useState([null, null]);
    const [queryPayload, setQueryPayload] = useState({
        search: '',
        // startDate: '',
        // endDate: '',
        page: 1,
        limit: 10,
    });

    const onChange = (name, value) => {
        let newPayload = {
            ...queryPayload,
            [name]: value
        }
        setQueryPayload(newPayload)
        if(value === ''){
            filterList(newPayload)
        }
    }
    
    const nextpage = (page) => {
        let qp = {...queryPayload}
        qp["page"] = page 
        setQueryPayload(qp)
        filterList(qp)
      };
      
      
    const onChangeDate = (value) => {
        setDate(value)
        let dateQuery = {
            startDate: moment(value[0]).format("YYYY-MM-DD"),
            endDate: moment(value[1]).format("YYYY-MM-DD")
        }
        onSelectionOfDate(dateQuery)
    }

    const onRowClick = (rowData,rowMeta) => {
        getApplicationDetails(rowData)
    }
   
    const isShowConfirmBtn  = () => {
        let show = true;

        if(_.isEmpty(queryPayload.search)) {
            show = false
        }

        return show
    }
    const createNew = () => {
        if(onCreateRedirect != null){
            history.push(onCreateRedirect)
        } else {
            triggerPopup()
        }
    }
   
    const rowEvents = () => {
        if (onClick) {
          return {
            onClick: (e, row, rowIndex) => {
              onClick(row);
            },
          };
        } else {
          return {};
        }
      };
    return (
        <>
        {isLoading ? 
            <></>
        :
            <div className='table_wrapper'>
                <div className='row table_selectors  align-items-center mb-2'>
                    <div className='col-lg-9 col-12'>
                    <div className='row py-2'>
                            {showSearch &&
                                <div className='col-xl-3 col-12'>
                                    <FormInput 
                                        placeholder="Search"
                                        name="search" 
                                        type = 'search'
                                        // label = "Search"
                                        helperBeforeIcon = "search"
                                        inputClassName = "common_fields"
                                        value={queryPayload['search']}
                                        onChange = {(...params) => onChange(...params)}
                                    />
                                </div>
                            }
                            {/* {showDateFilter &&
                                <>
                                    <div className='col-xl-3 col-12'>
                                        <FormInput 
                                            placeholder="Select From Date"
                                            name="fromDate" 
                                            label = 'Start Date'
                                            type = 'date'
                                            id = "startDate"
                                            helperBeforeIcon = "calendar"
                                            inputClassName = "date"
                                            value={queryPayload['startDate']}
                                            onChange = {(...params) => onChange(...params)}
                                        />
                                    </div>
                                    <div className='col-xl-3 col-12'>
                                        <FormInput 
                                            placeholder="Select To Date"
                                            name="toDate" 
                                            label = 'End Date'
                                            type = 'date'
                                            inputClassName = "common_fields"
                                            helperBeforeIcon = "calendar"
                                            value={queryPayload['endDate']}
                                            onChange = {(...params) => onChange(...params)}
                                        />
                                    </div>
                                </>
                            } */}
                            {isShowConfirmBtn() &&
                                <div className='col-xl-3 col-lg-3 col-md-3 col-6 align-self-end'>
                                    <CreateApplication
                                        onCreateApplicationBtnClick={() => filterList(queryPayload)}
                                        btnName={"Confirm"}
                                        btnClassname="text-white primary_bg"
                                        disableCreateApp={false}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    {showCreateBtn &&
                        <div className='col-lg-3 col-12 py-2'>
                            <div className='d-flex justify-content-end'>
                                <CreateApplication
                                    onCreateApplicationBtnClick={createNew}
                                    btnName={createBtnLabel}
                                    btnClassname="px-4 text-white primary_bg"
                                    disableCreateApp={false}
                                />
                            </div>
                        </div>
                    }
                </div>
                <BootstrapTable 
                    bootstrap4 
                    keyField={keyField} 
                    data={rows}  
                    columns={columns} 
                    rowEvents={ rowEvents()}
                    defaultSorted={sort} 
                    hover
                    bordered={ false }
                    // selectRow={selectRow}
                   
                    wrapperClasses="table-responsive custom-striped-table"
                    // { ...props.baseProps }
                />
                  <div className="pt-3">
                    <Pagination 
                        pageCount={pageMetaData?.totalPages} 
                        page={queryPayload.page}
                        onPageChange={nextpage}
                    />
                </div>
            </div>
    }

        </>
    )
}