import { order } from 'service/actionType';
import { API } from 'service/api';
import { API_BOOK } from 'service/endpoints';
import { Toast } from 'helpers/Toast';

let { ORDER_LIST_API, UPDATE_ORDER_API, GET_ORDER_BY_ID_API, DELIVERY_PARTNER_ORDERS_LIST_API,GET_COMPLETED_ORDER_API,GET_OPEN_ORDER_API } = API_BOOK.MAIN_APP.ORDER;
let {
  IS_ORDER_LIST_FETCHING,
  IS_ORDER_DETAIL_FETCHING,
  IS_DELIVERY_PARTNER_LIST_FETCHING,

  DELIVERY_PARTNER_DELIVERED_ORDERS,
  DELIVERY_PARTNER_ASSIGNED_ORDERS,
  ORDER_LIST,
} = order.ORDER;

export const getOrderList = (queryPayload) => async (dispatch, getState) => {
  try {
    dispatch({ type: IS_ORDER_LIST_FETCHING, payload: true });
    let requestPayload = {
      ...ORDER_LIST_API,
      params: {
        ...queryPayload,
      },
    };
    let response = await API(requestPayload);
    if (response.status == 201) {
      dispatch({ type: ORDER_LIST, payload: response.data });
      dispatch({ type: IS_ORDER_LIST_FETCHING, payload: false });
    } else {
      Toast('error', response?.data?.message);
      dispatch({ type: IS_ORDER_LIST_FETCHING, payload: false });
    }
  } catch (error) {
    Toast('error', error?.response?.data?.message);
  }
};

export const updateOrder = (id,payload) => async (dispatch, getState) => {
  try {
    dispatch({ type: IS_ORDER_DETAIL_FETCHING, payload: true });
    // console.log('find the ID', id);
    // console.log(
    //   'Find the url',
    //   UPDATE_ORDER_API.url,
    //   `${UPDATE_ORDER_API.url}/${id}`
    // );
    const requestPayload = {
      ...UPDATE_ORDER_API,
      url: `${UPDATE_ORDER_API.url}/${id}`,
      data: payload
    };

    let response = await API(requestPayload);
    if (response.status == 200) {
      dispatch({ type: IS_ORDER_DETAIL_FETCHING, payload: false });
    } else {
      dispatch({ type: IS_ORDER_DETAIL_FETCHING, payload: false });
      Toast('error', response?.data?.message);
    }
  } catch (error) {
    dispatch({ type: IS_ORDER_DETAIL_FETCHING, payload: false });
    Toast('error', error?.response?.data?.message);
  }
};


export const getSingleOrderDetail = (id,payload) => async (dispatch, getState) => {
  try {
    dispatch({ type: IS_ORDER_DETAIL_FETCHING, payload: true });
    // console.log('find the ID', id);
    // console.log(
    //   'Find the url',
    //   UPDATE_ORDER_API.url,
    //   `${UPDATE_ORDER_API.url}/${id}`
    // );
    const requestPayload = {
      ...GET_ORDER_BY_ID_API,
      url: GET_ORDER_BY_ID_API.url + `/${id}`,
    };

    let response = await API(requestPayload);
    if (response.status == 200) {
      dispatch({ type: IS_ORDER_DETAIL_FETCHING, payload: false });
    } else {
      dispatch({ type: IS_ORDER_DETAIL_FETCHING, payload: false });
    }
  } catch (error) {
    dispatch({ type: IS_ORDER_DETAIL_FETCHING, payload: false });
    Toast('error', error?.response?.data?.message);
  }
};

export const getdeliveryPartnerAssignedOrders = (queryPayload) => async (dispatch, getState) => {
  try {
    dispatch({ type: IS_DELIVERY_PARTNER_LIST_FETCHING, payload: true });
    let requestPayload = {
      ...DELIVERY_PARTNER_ORDERS_LIST_API,
      params: {
        ...queryPayload,
      },
    };
    let response = await API(requestPayload);
    if (response.status == 200) {
      dispatch({ type: DELIVERY_PARTNER_ASSIGNED_ORDERS, payload: response.data });
      dispatch({ type: IS_DELIVERY_PARTNER_LIST_FETCHING, payload: false });
    } else {
      dispatch({ type: IS_DELIVERY_PARTNER_LIST_FETCHING, payload: false });
    }
  } catch (error) {
    Toast('error', error?.response?.data?.message);
  }
};

export const getDeliveryParterOrdersList = (queryPayload) => async (dispatch, getState) => {
  try {
    dispatch({ type: IS_DELIVERY_PARTNER_LIST_FETCHING, payload: true });
    let requestPayload = {
      ...DELIVERY_PARTNER_ORDERS_LIST_API,
      params: {
        ...queryPayload,
      },
    };
    let response = await API(requestPayload);
    if (response.status == 200) {
      dispatch({ type: DELIVERY_PARTNER_DELIVERED_ORDERS, payload: response.data });
      dispatch({ type: IS_DELIVERY_PARTNER_LIST_FETCHING, payload: false });
    } else {
      dispatch({ type: IS_DELIVERY_PARTNER_LIST_FETCHING, payload: false });
    }
  } catch (error) {
    Toast('error', error?.response?.data?.message);
  }
};
