import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Label,
} from 'reactstrap';

import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormikFormInput from 'components/FormInput/formikForInput';

import BackBtn from 'components/Button/backBtn';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { API } from 'service/api';
import { API_BOOK } from 'service/endpoints';
import ToggleSwitch from 'components/Switch';
// Custom
import * as adminAction from 'actions/admin';

const CreateUserManagementFunc = (props) => {
  const defaultUserData = {
    userName: '',
    email: '',
    password: '',
    mobile: '',
    userImage: '',
    roleId: '',
  };
  const {
    match: { params },
  } = props;

  const [isEdit, setEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [userDetail, setDetail] = useState({
    userName: null,
    email: null,
    password: null,
    mobile: null,
    userImage: null,
    roleId: null,
    status:null
  });

  const [queryPayload, setQueryPayload] = useState({
    search: '',
  });

  useEffect(() => {
    getApis();
  }, [params]);

  const getApis = async () => {
    if (params && params.id && !isEdit) {
      await getSingleUserDetail(params.id);
    }
  };
  const getSingleUserDetail = async (id) => {
    try {
      let { GET_ADMIN_BY_ID } = API_BOOK.MAIN_APP.ADMIN;
      let requestPayload = {
        ...GET_ADMIN_BY_ID,
        url: GET_ADMIN_BY_ID.url + `/${id}`,
      };

      const res = await API(requestPayload);
      if (res && !_.isEmpty(res.data)) {
        setEdit(true);
        setDetail({
          ...userDetail,

          id: res.data.id,
          userName: res.data.userName,
          password: res.data.password,
          email: res.data.email,
          mobile: res.data.mobile,
          roleId: res.data.roleId,
          status: res.data.status === 1 ? true : false,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getAdminList = async (queryPayload) => {
    await props.getAdminList(queryPayload);
  };
  const onChange = (name, value) => {
    console.log('name, value',name, value)
    if (name == ' userImage') {
      uploadImage(value);
    } else {
      setDetail({ ...userDetail, [name]: value });
    }
  };

  const filterList = (payload) => {
    setQueryPayload({ ...queryPayload, ...payload });
    getAdminList(payload);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      userName: (userDetail && userDetail.userName) || '',
      email: (userDetail && userDetail.email) || '',
      password: (userDetail && userDetail.password) || '',
      mobile: (userDetail && userDetail.mobile) || '',
      roleId: (userDetail && userDetail.roleId) || '',
    },
    validationSchema: Yup.object({
      userName: Yup.string().required('Please Enter Your Name'),
      email: Yup.string().required('Please Enter Eamil'),
      password: Yup.string().required('Password Must be 8 charcters'),
      mobile: Yup.string().required('Please Enter Your Mobile Number'),
    }),
    onSubmit: async (values) => {
      let payload = {
        userName: values.userName,
        email: values.email,
        password: values.password,
        mobile: values.mobile,
        roleId: parseInt(values.roleId),
        status: userDetail.status === true ? 1 : 2,
      };
      let res;
      if (isEdit) {
        res = await props.updateAdmin(payload, userDetail.id);
      } else {
        res = await props.createAdmin(payload);
      }
      if (res) {
        validation.resetForm();
        setDetail({ ...userDetail, ...defaultUserData });
      }
    },
  });
  const uploadImage = async (value) => {
    let formData = new FormData();
    formData.append('image', value);
    // const requestPayload = {
    //     ...API_BOOK.MAIN_APP.COMMON.UPLOAD_DOC_API,
    //     data: formData
    // };
    await axios
      .post('http://api.kasapp.in/api/admin/upload', formData)
      .then((res) => {
        if (res.status === 200) {
          setDetail({
            ...validation.values,
            userImage: res?.data?.data?.imageUrl,
          });
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  return (
    <div className='page-content'>
      <Container fluid>
        <>
          <Row>
            <Col xs='12'>
              <div className='page-title-box d-flex'>
                <BackBtn history={props.history} />
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardTitle className='h4 mb-4'>{isEdit ? 'Edit Customer' : 'Create Customer'}</CardTitle>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col className='col-lg-12'>
                        <>
                          <Row>
                            <Col className='col-12' lg='3' md='3'>
                              <FormikFormInput
                                placeholder='roleId'
                                name='roleId'
                                type='select'
                                formGroupClassName='mb-3'
                                label='Select Role'
                                defaultSelect={true}
                                onChange={(...params) => onChange(...params)}
                                options={[
                                  {
                                    label: 'SUPER ADMIN',
                                    value: 1,
                                  },
                                  {
                                    label: 'ADMIN',
                                    value: 2,
                                  },
                                  {
                                    label: 'DELIVERY PARTNER',
                                    value: 3,
                                  },
                                ]}
                                labelClassName='col-form-label'
                                value={userDetail['roleId']}
                                validation={validation}
                              />
                            </Col>
                            <Col className='col-12' lg='3' md='3'>
                              <FormikFormInput
                                placeholder='userName'
                                name='userName'
                                type='text'
                                formGroupClassName='mb-3'
                                label='user Name'
                                labelClassName='col-form-label'
                                validation={validation}
                              />
                            </Col>
                            <Col className='col-12' lg='3' md='3'>
                              <FormikFormInput
                                placeholder=' email'
                                name='email'
                                type='text'
                                formGroupClassName='mb-3'
                                label='Email'
                                labelClassName='col-form-label'
                                validation={validation}
                              />
                            </Col>
                            {!isEdit && (
                              <Col className='col-12' lg='3' md='3'>
                                <FormikFormInput
                                  placeholder='password'
                                  name='password'
                                  type='text'
                                  formGroupClassName='mb-3'
                                  label='Enter your Password'
                                  labelClassName='col-form-label'
                                  value={userDetail['password']}
                                  validation={validation}
                                />
                              </Col>
                            )}
                            <Col className='col-12' lg='3' md='3'>
                              <FormikFormInput
                                placeholder='Enter Mobile Number'
                                name='mobile'
                                type='number'
                                formGroupClassName='mb-3'
                                label='Enter Mobile Number'
                                labelClassName='col-form-label'
                                value={userDetail['mobile']}
                                validation={validation}
                              />
                            </Col>
                            {isEdit && (
                              <Col className='col-12' lg='3' md='3'>
                              <Label>Status</Label>
                              <ToggleSwitch
                                label=''
                                name='status'
                                id={userDetail.id}
                                checked={userDetail.status}
                                onChange={(val) => onChange('status', val)}
                              />
                            </Col>
                            )}
                          </Row>
                        </>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className='d-flex justify-content-end'>
                          <button type='submit' className='px-4 primary_btn'>
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      </Container>
    </div>
  );
};
const mapStateToProps = ({ admin, loader }) => {
  return {
    admin,
    loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(adminAction, dispatch);
};

const CreateUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserManagementFunc);

export { CreateUser };
