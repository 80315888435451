import _ from "lodash";
import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  FormFeedback,
  Label,
  Input,
} from "reactstrap"
import RemoveImage from 'assets/images/remove.svg';
import { Toast } from "helpers/Toast";
import { validateFilesize } from "helpers/utils";

export const LogoUpload = ({title, preview, name,onChange, groupClassName, labelClassName, validation, removeImage}) => {

    const setChange = (name, files) => {
        let isValidFilesize = validateFilesize(files)
        if(isValidFilesize){
            validation.setFieldValue(name, files[0])
            onChange(name, files[0])
        }
    }

    

    return (
        <div className={groupClassName}>
            <Label htmlFor="formFileSm" className={labelClassName}>{title}</Label>
            <Col>
                {!_.isEmpty(preview) &&
                    <div className="image_wrapper p-15 logo_img_wrapper text-center mb-3">
                        <img
                            data-dz-thumbnail=""
                            className="avatar-md rounded bg-light"
                            alt={""}
                            src={preview}
                        />
                        <div className="remove_image_ic" onClick={() => removeImage()}>
                            <img src={RemoveImage} />
                        </div>
                    </div>
                } 
                <Input 
                    className="form-control form-control-md" 
                    id="formFileSm" 
                    type="file" 
                    name={name}
                    // value={validation.values[name] || ""}
                    onClick={(event)=> { 
                        event.target.value = null
                    }}
                    onChange={(e) => setChange(name, e.target.files)}
                />
                {validation.errors[name] ? (
                    <FormFeedback type="invalid" className="invalid_form_field">
                        {validation.errors[name]}
                    </FormFeedback>
                    ) : null
                }
            </Col>
        </div>
    )
}

                            