import React, { useState } from 'react';
import Select from 'react-select'
import makeAnimated from "react-select/animated";
import { Col,FormGroup } from 'reactstrap';

const animatedComponents = makeAnimated();

const ReactSelect = (props) => {
    return (
        <div>
            <div className={`templating-select select2-container ${props.formGroupClassName}`}>
                {props.label &&
                    <label 
                        className={`control-label ${props.labelClassName}`}
                    >
                        {props.label}
                        {props.spnText &&  <span className="spn-text">&nbsp; ( {props.spnText} )</span> }
                    </label>
                }
                <Col>
                    <Select
                        options={props.options}
                        defaultValue={props.value}
                        value={props.value}
                        styles={props.styles}
                        isMulti={props.isMulti}
                        isClearable={props.isClearable}
                        placeholder={props.placeholder}
                        classNamePrefix="select2-selection"
                        closeMenuOnSelect={props.closeMenuOnSelect}
                        onChange={(val) => props.onChange(props.name, val)}
                        isOptionDisabled={(option) => props.isMulti ? props.value.length >= props.maxLimit : false}
                        components={animatedComponents}
                    />
                </Col>
                
                {(props.spanText !== '' && props.spanText !== undefined)? <label className='spantext_label'>{props.spanText}</label> : ''}
                <p className="text-danger small">
                    {props.error ? props.error : ''}
                </p>
            </div>
        </div>

    );
}

ReactSelect.defaultProps = {
    onChange: () => { },
    options:[],
    value:'',
    classNamePrefix:'form-group',
    isMulti:false
}
export default ReactSelect;