import React, { Component, Fragment, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Table,
  Card,
  CardBody,
  CardText,
} from 'reactstrap';
import { BasicTable } from 'components/Table/BasicTable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { axios, API, API_BOOK } from 'service';
import { ORDER } from 'helpers/variables.js';
import nobooking from 'assets/images/nobooking.png';
import { BookingsTableList } from 'helpers/tableDataMapper';
import * as orderAction from 'actions/order';
import { ORDER_ASSIGNED_TABS } from 'helpers/variables';
import BasicTab from 'components/Tabs/BasicTab';
import { Link } from 'react-router-dom';
import { ModalContainer } from 'components/Modal';
import FormInput from 'components/FormInput/index';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormikFormInput from 'components/FormInput/formikForInput';
const OpenOrdersFunc = (props) => {
  const { getOrdersList } = props;
  const [statusList] = useState([
    {
      label: 'pickup',
      value: 3,
      disabled: false,
    },
    {
      label: 'Delivered',
      value: 4,
      disabled: false,
    },
  ]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  // const handlePopup = (order) => {
  //   setIsPopupOpen(true);
  //   setSelectedOrder(order);
  // };
  const handlePopup = (id) => {
    setIsPopupOpen(true);
    setSelectedOrder({ id: id });
  };

  const onChange = (name, value) => {
    const sOrder = { ...selectedOrder };
    sOrder[name] = value;
    setSelectedOrder(sOrder);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: (selectedOrder && selectedOrder.status) || '',
    },
    validationSchema: Yup.object({
      status: Yup.string().required('Status is required'),
    }),
    onSubmit: async (values) => {
      try {
        let { UPDATE_ORDER_API } = API_BOOK.MAIN_APP.ORDER;
        const requestPayload = {
          ...UPDATE_ORDER_API,
          url: UPDATE_ORDER_API.url + `/${selectedOrder.id}`,
          data: {
            ...values,
            status: parseInt(values.status),
          },
        };
        const res = await API(requestPayload);
        if (res.status === 200) {
          setSelectedOrder(null);
          setIsPopupOpen(false);
          getOrdersList(props.orderStatus[0]);
        }
      } catch (error) {
        console.log('Error updating order:', error);
      }
    },
  });

  const orders = props.order.deliveryPartnerDeliveredOrders.list;

  return (
    <React.Fragment>
      <div>
        {orders.length === 0 ? (
          <Container>
            <Row>
              <Col md='6' lg='12' className='mt-5'>
                <img
                  src={nobooking}
                  className='mx-auto d-block'
                  style={{ maxWidth: '50%' }}
                />
              </Col>
            </Row>
          </Container>
        ) : (
          <Container fluid className='p-0 mt-10'>
            {orders.map((order) => (
              <Row>
                <Col lg='12'>
                  <Card>
                    <CardBody>
                      <Row className='p-2 align-items-center justify-content-between'>
                        <Col xs='auto' className='p-0'>
                          <h4 className='fw-700 m-0'>#{order.id}</h4>
                        </Col>
                        <Col
                          xs='auto'
                          className='p-0 d-none d-sm-block'
                          style={{ marginTop: '10px' }}
                        >
                          {order.status === '3' ? (
                            <p
                              className='order_status bt'
                              style={{ backgroundColor: '#0707b3' }}
                            >
                               Picked Up
                            </p>
                          ) : null}
                          {order.status === '2' ? (
                            <p
                              className='order_status bt'
                              style={{backgroundColor: "#00b2ff"}}
                            >
                               Confirmed
                            </p>
                          ) : null}
                        </Col>
                        <Col xs='auto' className='p-0'>
                          <div style={{ width: '150px', marginTop: '10px' }}>
                            <Button
                              color='primary w-100 border-none'
                              className='fw-600'
                              type='button'
                              onClick={() => handlePopup(order.id)}
                            >
                              Update status
                            </Button>
                          </div>
                        </Col>
                      </Row>

                   
                      <hr />
                      <ModalContainer
                        isOpen={isPopupOpen}
                        toggle={() => setIsPopupOpen(false)}
                        title='Update Status'
                      >
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit(order.id);
                            return false;
                          }}
                        >
                          <Row>
                            <Col className='col-lg-12'>
                              <Row>
                                <Col className='col-12'>
                                  <FormikFormInput
                                    placeholder='Status'
                                    name='status'
                                    type='select'
                                    formGroupClassName='mb-3'
                                    label='Status'
                                    defaultSelect={true}
                                    onChange={(...params) =>
                                      onChange(...params)
                                    }
                                    options={[
                                      {
                                        label: 'PICKED UP',
                                        value: '3',
                                      },
                                      {
                                        label: 'DELIVERED',
                                        value: '4',
                                      },
                                    ]}
                                    labelClassName='col-form-label'
                                    value={order.status}
                                    validation={validation}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col className='col-lg-3 col-md-3 col-12'>
                                  <Button
                                    color='primary w-100 border-none'
                                    className='fw-600 mb-3 mt-3'
                                    type='submit'
                                  >
                                    Update
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </ModalContainer>
                      <div className='application-detail'>
                        <Row>
                          <Col
                            xs='auto'
                            className='p-0 d-sm-none'
                            style={{ marginTop: '10px' }}
                          >
                            {order.status === '3' ? (
                              <p
                                className='order_status bt'
                                style={{ backgroundColor: '#0707b3' }}
                              >
                                Picked Up
                              </p>
                            ) : null}
                            {order.status === '2' ? (
                            <p
                              className='order_status bt'
                              style={{backgroundColor: "#00b2ff"}}
                            >
                               Confirmed
                            </p>
                          ) : null}
                          </Col>

                          <Col lg={6}>
                            <div className='py-2 mt-3'>
                              <h3 className='font-size-15 fw-bold'>
                                Customer Details
                              </h3>
                            </div>
                            <div className='table-responsive'>
                              <Table striped>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Mobile Number</th>
                                    <th>Address</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{order.customerDetails.userName}</td>
                                    <td>{order.customerDetails.mobile}</td>
                                    <td>
                                      <div>
                                        {order?.orderAddress?.addressLine1}{' '}
                                        {order.orderAddress?.addressLine2},
                                      </div>
                                      <div>
                                        {order?.orderAddress?.landmark},{' '}
                                        {order?.orderAddress?.postalCode}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                          <Col
                            lg={4}
                            md={12}
                            className='mt-3'
                            style={{ marginLeft: 'auto' }}
                          >
                            <div className='py-2'>
                              <h3 className='font-size-15 fw-bold'>
                                Product Summary
                              </h3>
                            </div>
                            <div className='table-responsive'>
                              <Table striped>
                                <thead>
                                  <tr>
                                    <th>Product Name</th>
                                    <th>Quantity</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {order?.orderDetails.map((pr) => (
                                    <tr>
                                      <td>{pr.productName}</td>
                                      <td>{`${pr.quantity} x  ${pr.unit} ${pr.unitType}`}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            <div
                              className='mt-3'
                              style={{ textAlign: 'right' }}
                            >
                              <h4>Total: Rs.{order.totalAmount}</h4>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ))}
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ order, profile, loader }) => {
  return {
    order,
    profile,
    loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(orderAction, dispatch);
};

const OpenOrders = connect(mapStateToProps, mapDispatchToProps)(OpenOrdersFunc);

export default OpenOrders;
