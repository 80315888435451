import React, { Component, Suspense } from 'react';
import { Route, Router, Switch } from "react-router-dom";

import routers from "./routes"
import { history } from "helpers/utils"
import * as Layout from "layout";
import * as Pages from "pages"
import { connect } from 'react-redux';
import { ToastBar, Toaster } from 'react-hot-toast';

import moment from 'moment-timezone';

moment.tz('Asia/Kolkata'); 

class RoutesClass extends Component {

    constructor(props) {
        super(props)
        this.state = {
            renderRoute: false,
            pathname: null,
            loading: true
        }
    }
    
    render() {
        return (
            <Router history={history} >   
                <Suspense fallback={<div>Loading...</div>}>
                    {routers.map(({ component, redirect, path, exact = false, childrens= [] }) => {
                        if (childrens.length > 0) {
                            return (
                            <Route
                                path={path}
                                exact={exact}
                                key={path}
                                render={(props) => {

                                    if (redirect) {
                                        if (props.location.pathname === path) {
                                            props.history.push(redirect)
                                        }
                                    }
                                    console.log('component',component, childrens)
                                    const LayoutComponent = Layout[component]
                                    return (

                                        <LayoutComponent {...props} >
                                            <Switch>
                                            {childrens.map(({ component: ChildrenComponent, path: childrenPath, exact = false, auth = true }) => {
                                                return <Route
                                                    path={path + childrenPath}
                                                    exact={exact}
                                                    key={path + childrenPath}
                                                    render={(props) => {

                                                        let PageComponent = Pages[ChildrenComponent]
                                                        return <PageComponent {...props} />
                                                    }}

                                                />

                                            })}
                                            </Switch>
                                        </LayoutComponent>
                                    )

                                }}
                            />
                            )
                        }
                        return (
                            <Route
                            path={path}
                            exact={exact}
                            key={component}
                            render={(props) => {
                                if (component) {
                                console.log('outsidecomponent', component);
                                const PageComponent = Pages[component]
                                return <PageComponent />;
                                }
            
                                if (redirect) {
                                if (props.location.pathname === path) {
                                        props.history.push(redirect)
                                }
                                }
            
                                return <div />;
                            }}
                            />
                        );
                    })}
                </Suspense>  
                <Toaster
                    position="top-right"
                    gutter={8}
                    reverseOrder
                    containerClassName="custom-toast"
                    containerStyle={{ right: '40px', bottom: '50px' }}
                >
                    {(t) => (
                        <ToastBar toast={t} style={{ padding: 0, overflow: 'hidden' }}>
                        {({ icon, message }) => {
                            return (
                            <div
                                className={`custom-toast-${t.type} d-flex align-items-center custom-toast-item`}
                            >
                                {icon}
                                {message}
                            </div>
                            );
                        }}
                        </ToastBar>
                    )}
                </Toaster>
            </Router>  

        )
    }
}

export default RoutesClass