import React, { useEffect, useState } from "react";
import {
    Container,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MdEdit } from "react-icons/md";


// custom
import * as adminAction from "actions/admin";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { AdminsTableList } from "helpers/tableDataMapper";

const UserMangementFunc = (props) => {

    const dispatch = useDispatch();

    const [queryPayload, setQueryPayload] = useState({
        search: ''
    })
    const { adminList } = useSelector(state => ({
        adminList: state.admin.adminList,
    }));

    useEffect(() => {
        getAdminList(queryPayload)
    }, [dispatch]);

    const getAdminList = async (queryPayload) => {
        await props.getAdminList(queryPayload)
    }




    const filterList = (payload) => {
        setQueryPayload({ ...queryPayload, ...payload })
        getAdminList(payload)
    }


    const getTableColumns = () => {
        let editObj = {
            text: "Actions",
            formatter: (cellContent, row, rowIndex) => (
                <div className='d-flex align-items-center justify-content-center'>
                    <div
                        onClick={() => getApplicationDetails(row, true)}

                    >
                        <MdEdit size={18} />
                    </div>
                </div>
            )
        }

        let newtable = [...AdminsTableList, editObj]
        return newtable
    }
    const getApplicationDetails = (row) => {
        props.history.push(`/staff/user/management/create/${row.id}`)
    }
    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 998) {
            body.classList.remove("sidebar-enable");
        }

    }
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {/* <CardTitle className="page-title-box">USER MANAGEMENT</CardTitle> */}
                    <CustomBootstrapTable
                        rows={adminList?.list}
                        pageMetaData={adminList?.pageMetaData}
                        columns={getTableColumns()}
                        isLoading={false}
                        keyField={'id'}
                        filterList={(...params) => filterList(...params)}
                        getApplicationDetails={(row) => getApplicationDetails(row, true)}
                        showStatusFilter={false}
                        showDateFilter={false}

                        showSearch={true}

                        createBtnLabel="Create User"
                        onCreateRedirect={'/staff/user/management/create'}
                        showCreateBtn={true}
                        history={props.history}
                    />
                </Container>
            </div>
        </>
    )
}


const mapStateToProps = ({ admin, loader }) => {
    return {
        admin,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(adminAction, dispatch);
};
const UserManagement = connect(mapStateToProps, mapDispatchToProps)(UserMangementFunc)
export { UserManagement }