import React, { Component } from 'react'
import { Container,Row, Col,Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DeliveredTableColumns } from 'helpers/tableDataMapper'
import * as orderAction from "actions/order";
import { ORDER_ASSIGNED_TABS  } from 'helpers/variables'
import  BasicTab  from "components/Tabs/BasicTab";
import OpenOrders from './statusTabs/OpenOrders';
import DeliveredOrders from './statusTabs/DeliveredOrders';
import FormLoader from 'components/loader/formLoader';
import Header from 'components/navs/Header';
class AppListClass extends Component {
    state = {
        orderStatus: [1],
    }
    componentDidMount() {
        let {orderStatus} = this.state
        let payload  = orderStatus[0]
        this.getOrdersList(payload)
    }
    
    getOrdersList = (orderStatus) => {
        let payload = {
            orderStatus : orderStatus
        }
        this.props.getDeliveryParterOrdersList(payload);
    };
    onChange = (name, value) => {
        let newval = value 
        let payload = newval[0]
        this.getOrdersList(payload)
        this.setState({
            [name]: value
        })
    }
    
    getComponentBasedOnStatus = (orderStatus) => {
        let component = ''
        switch(orderStatus[0]){
            case 1: 
                component = OpenOrders
                break;
            case 2: 
                component = DeliveredOrders
                break;
        }
        return component
    }
    tToggle() {
        var body = document.body;
        if (window.screen.width <= 998) {
            body.classList.remove("sidebar-enable");
        } 
      }
      
    render() {
        let {orderStatus} = this.state
        let AddComponent = this.getComponentBasedOnStatus(orderStatus)
        return (
            <>
            <div className='page-content'>
                <BasicTab
                    tabs={ORDER_ASSIGNED_TABS}
                    name="orderStatus"
                    onChange={this.onChange}
                    activeTab={orderStatus}
                />
                <Row noGutters>
                    <Col>
                        {this.props.order.isDeliveryPartnerListFetching ? 
                            <FormLoader/> 
                            :
                            <AddComponent {...this.state} {...this.props} updatestatus={this.updatestatus} getOrdersList={this.getOrdersList}  filterList={this.filterList}/>
                        }
                    </Col>
                </Row>
            </div>
            </> 
        );
    }
}

const mapStateToProps = ({ order, profile,loader }) => {
    return {
        order,
        profile,
        loader,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(orderAction, dispatch);
};

const DeliveryPartnerAssignedOrders =  connect(mapStateToProps, mapDispatchToProps)(AppListClass);

export { DeliveryPartnerAssignedOrders }

let inputValidations = {
    selectedStatus: {
        presence: {
            allowEmpty: false,
            message: "^status can't be blank"
        }
    }
}

