import { login, profileActionType } from '../service/actionType'

const initialState = {
    "_id": null,
    "userName": null,
    "mobile": null
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case profileActionType.UPDATE_PROFILE_DETAILS:
            return {
                ...initialState,
                "_id": payload._id,
                "userName": payload.userName,
                "mobile": payload.mobile
            }
        case login.ADMIN_LOGIN:
            return {
                ...initialState,
                "_id": payload._id,
                "name": payload.userName,
                "mobile": payload.mobile
            }
        default:
            return state
    }

}