import { category } from 'service/actionType'
import { API } from 'service/api'
import { API_BOOK } from 'service/endpoints'
import { Toast } from 'helpers/Toast'
import _ from 'lodash'


let { CATEGORY_LIST_API, CREATE_CATEGORY_API, UPDATE_CATEGORY_API, DELETE_CATEGORY } = API_BOOK.MAIN_APP.CATEGORY
let {
    IS_CATEGORY_LIST_FETCHING,
    IS_CATEGORY_DETAIL_FETCHING,

    CATEGORY_LIST
} = category.CATEGORY

let sampleListData = {
    data: [],
     pageMetaData: {
        count: 0,
        limit: 10,
        page: 1,
        totalPages: 0
    }
}

export const getCategoryList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type:  IS_CATEGORY_LIST_FETCHING, payload:  true })
        let requestPayload = {
            ...CATEGORY_LIST_API,
            params: {
                ...queryPayload
            }
        }
        let response = await API(requestPayload)
        if(response.status == 201 ) {
            dispatch({ type: CATEGORY_LIST, payload: response.data})
            dispatch({type: IS_CATEGORY_LIST_FETCHING, payload:false})
        } else {
            dispatch({ type: CATEGORY_LIST, payload: sampleListData})
            dispatch({type: IS_CATEGORY_LIST_FETCHING, payload:false})
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message);
    }
}

export const createCategory = (payload) => async (dispatch, getState) => {
    try {
        
        dispatch({ type: IS_CATEGORY_DETAIL_FETCHING, payload: true })
        let requestPayload = {
            ...CREATE_CATEGORY_API,
            data: payload
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            dispatch({ type: IS_CATEGORY_DETAIL_FETCHING, payload: false })
        } else {
            Toast('error', response?.data?.message)
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message)
    }
}

export const updateCategory = (payload,id) => async (dispatch, getState) => {
    try {
        dispatch({ type: IS_CATEGORY_DETAIL_FETCHING, payload: true })
        let requestPayload = {
            ...UPDATE_CATEGORY_API,
            url:`${UPDATE_CATEGORY_API.url}/${id}`,
            data: payload
             
        }
        let response = await API(requestPayload)
        if (response.status == 201) {
            dispatch({ type: IS_CATEGORY_DETAIL_FETCHING, payload: false })
        } else {
            dispatch({ type: IS_CATEGORY_DETAIL_FETCHING, payload: false })
            Toast('error', response?.data?.message)
        }
    } catch (error) {
        dispatch({ type: IS_CATEGORY_DETAIL_FETCHING, payload: false })
        Toast('error', error?.response?.data?.message)
    }
}

 
  
  
  

export const deleteCategory = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: IS_CATEGORY_DETAIL_FETCHING, payload: true })
        let requestPayload = {
            ...DELETE_CATEGORY,
            url: `${DELETE_CATEGORY.url}/status/${id}`,
        }
        let response = await API(requestPayload)
        if (response.status === 201) {
            dispatch({ type: IS_CATEGORY_DETAIL_FETCHING, payload: false })
            Toast('success', "Successfully Updated")
        } else {
            Toast('error', response?.data?.message)
            dispatch({ type: IS_CATEGORY_DETAIL_FETCHING, payload: false })
        }
    } catch (error) {
        dispatch({ type: IS_CATEGORY_DETAIL_FETCHING, payload: false })
        Toast('error', error?.response?.data?.message)
    }
}
