import React, {useEffect} from 'react'
import { connect } from "react-redux";
import { DeliveredTableColumns } from 'helpers/tableDataMapper'
import { CustomBootstrapTable } from "components/Table/CustomBootstrapTable";


const DeliveredOrdersFunc = (props) => {
    return (
        <>
        <div className="mt-2 pt-2 bg-white">
            <CustomBootstrapTable
                rows = {props.order.deliveryPartnerDeliveredOrders?.list}
                pageMetaData={props.order.deliveryPartnerDeliveredOrders?.pageMetaData}
                columns = {DeliveredTableColumns}
                onClick={() => null}
                keyField = "id"
                showFilter={false}
                showDateFilter={false}
                filterList={props.filterList}
            />
        </div>
        </>
    );
}

const mapStateToProps = ({ order }) => {
    return {
        order,
    };
};

const DeliveredOrders = connect(mapStateToProps, null)(DeliveredOrdersFunc);
 
export default DeliveredOrders