import React, { useEffect, useState } from 'react';
import {
  Container,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// custom
import * as orderAction from 'actions/order';
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { OrdersTableList } from 'helpers/tableDataMapper';
import { OffcanvasComponent } from 'pages/orders/offCanvas';
import Header from 'components/navs/Header'

const OrdersMangementFunc = (props) => {
  const [isRight, setIsRight] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const dispatch = useDispatch();

  const [queryPayload, setQueryPayload] = useState({
    search: '',
  });

  const { orderList } = useSelector((state) => ({
    orderList: state.order.orderList,
  }));

  useEffect(() => {
    getOrderList();
  }, [dispatch]);

  const getOrderList = async () => {
    await props.getOrderList(queryPayload);
  };

  const filterList = async (payload) => {
    setQueryPayload({ ...queryPayload, ...payload });
    await props.getOrderList(payload);
  };

  const toggleRightCanvas = (row) => {
    setSelectedOrderId(row.id);
    setIsRight(!isRight);
  };
  
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
        body.classList.remove("sidebar-enable");
    } 

  }
  return (

    <div className='page-content'>
        <Container fluid>
          <CustomBootstrapTable
            rows={orderList?.list}
            pageMetaData={orderList?.pageMetaData}
            columns={OrdersTableList}
            isLoading={false}
            keyField={'id'}
            filterList={(...params) => filterList(...params)}
            showStatusFilter={false}
            showDateFilter={true}
            onClick={toggleRightCanvas}
            showSearch={true}

            showCreateBtn={false}
            history={props.history}
          />
          {selectedOrderId != null &&
            <OffcanvasComponent
              isRight={isRight}
              setIsRight = {setIsRight}
              selectedOrderId={selectedOrderId}
              getOrderList = {getOrderList}
              setSelectedOrderId = {setSelectedOrderId}
            />
          }
        </Container>
      </div>
  );
};

const mapStateToProps = ({ order, loader }) => {
  return {
    order,
    loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(orderAction, dispatch);
};
const Orders = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersMangementFunc);
export { Orders };
