import React, { useEffect, useState } from 'react';
import { Container, Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Custom
import * as customerAction from 'actions/customer';
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { CustomerTableColumns } from 'helpers/tableDataMapper';
import Header from 'components/navs/Header';

const CustomersManagementFunc = (props) => {
  const dispatch = useDispatch();

  const [queryPayload, setQueryPayload] = useState({
    search: '',
  });
  const { customerList } = useSelector((state) => ({
    customerList: state.customer.customerList,
  }));

  useEffect(() => {
    getCustomerList(queryPayload);
  }, [dispatch]);

  const getCustomerList = async (queryPayload) => {
    await props.getCustomerList(queryPayload);
  };

  const filterList = (payload) => {
    const newPayload = { ...queryPayload, ...payload };
    setQueryPayload(newPayload);
    getCustomerList({ ...newPayload, search: payload.search }); // Add search query to payload
  };
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
        body.classList.remove("sidebar-enable");
    } 

  }
  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Col md={6}></Col>
          <CustomBootstrapTable
            rows={customerList?.list}
            pageMetaData={customerList?.pageMetaData}
            columns={CustomerTableColumns}
            isLoading={false}
            keyField={'id'}

            filterList={filterList}
            showStatusFilter={false}
            showDateFilter={false}
            showSearch={true}
            history={props.history}
          />
        </Container>
      </div>
    </>
  );
};
const mapStateToProps = ({ customer, loader }) => {
  return {
    customer,
    loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(customerAction, dispatch);
};

const Customers = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomersManagementFunc);

export { Customers };
