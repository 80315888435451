import React, { useEffect, useState } from "react";
import {
    Container,
    Form,
    FormGroup,
    Input,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MdEdit } from "react-icons/md";

import Header from 'components/navs/Header'
// custom
import * as productAction from "actions/product";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { ProductTableColumns } from "helpers/tableDataMapper";
import ToggleSwitch from "components/Switch";



const ProductsMangementFunc = (props) => {

    const dispatch = useDispatch();

    const [queryPayload, setQueryPayload] = useState({
        search: '',
        page: 1,
        limit: 10
    })

    const { productList } = useSelector(state => ({
        productList: state.product.productList,
    }));

    useEffect(() => {
        getProductList(queryPayload)
    }, [dispatch]);



    const getProductList = async (queryPayload) => {
        await props.getProductList(queryPayload)
    }


    const filterList = (payload) => {
        setQueryPayload({ ...queryPayload, ...payload })
        getProductList(payload)
    }

    const toggleAvailablity = async (id) => {
        await props.deleteProduct(id)
        await props.getProductList(queryPayload)

    }

    const getTableColumns = () => {
        let editObj = {
            text: "Actions",
            formatter: (cellContent, row, rowIndex) => (
                <div className='d-flex align-items-center justify-content-center'>
                    <div
                        onClick={() => getApplicationDetails(row, true)}
                    >
                        <MdEdit size={18} />
                    </div>
                </div>
            )
        }
        let selectObj = {
            text: "Is Product Available",
            dataField: "isAvailable",
            formatter: (cellContent, row, rowIndex) => {
                return (
                    <ToggleSwitch
                        label=""
                        name="isAvailable"
                        id={row.id}
                        checked={row?.isAvailable == 1 ? true : false}
                        onChange={val => toggleAvailablity(row.id)}
                    />
                )
            }
        }

        let newtable = [...ProductTableColumns, editObj, selectObj]
        return newtable
    }
    const getApplicationDetails = (row) => {
        props.history.push(`/staff/products/create/product/${row.id}`)
    }
    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 998) {
            body.classList.remove("sidebar-enable");
        }

    }
    return (
        <>
            <div className='page-content'>
                <Container fluid>
                    <CustomBootstrapTable
                        rows={productList?.list}
                        pageMetaData={productList?.pageMetaData}
                        columns={getTableColumns()}
                        isLoading={false}
                        keyField={'id'}
                        filterList={(...params) => filterList(...params)}
                        getApplicationDetails={(row) => getApplicationDetails(row, true)}
                        showStatusFilter={false}
                        showDateFilter={false}

                        showSearch={true}

                        createBtnLabel="Create Product"
                        onCreateRedirect={'/staff/products/create/product'}
                        showCreateBtn={true}
                        history={props.history}

                    />
                </Container>
            </div>
        </>
    )
}


const mapStateToProps = ({ product, loader }) => {
    return {
        product,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(productAction, dispatch);
};
const Products = connect(mapStateToProps, mapDispatchToProps)(ProductsMangementFunc)
export { Products }