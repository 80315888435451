import React, { useEffect, useState } from 'react';
import { Container, Row, Form, Col, Label } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdEdit } from 'react-icons/md';

// custom
import * as categoryAction from 'actions/category';
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { CategoryTableColumns } from 'helpers/tableDataMapper';
import { ModalContainer } from 'components/Modal';
import FormikFormInput from 'components/FormInput/formikForInput';
import Header from 'components/navs/Header';
import { LogoUpload } from 'components/Uploadfile/LogoUpload';
import axios from 'axios';
import ToggleSwitch from 'components/Switch';

const CategorieManagementFunc = (props) => {
  const defaultCategoryData = {
    categoryName: '',
    categoryNameTamil: '',
    categoryImage: '',
  };

  const dispatch = useDispatch();
  const [isOpen, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [categoryDetail, setDetail] = useState({
    categoryName: null,
    categoryNameTamil: null,
    categoryImage: null,
  });
  const [queryPayload, setQueryPayload] = useState({
    search: '',
  });
  const { categoryList } = useSelector((state) => ({
    categoryList: state.category.categoryList,
  }));

  useEffect(() => {
    getCategoryList(queryPayload);
  }, [dispatch]);

  const getCategoryList = async (queryPayload) => {
    await props.getCategoryList(queryPayload);
  };

  const onChange = (name, value) => {
    if (name === 'categoryImage') {
      uploadImage(value);
    }
    setDetail({ ...categoryDetail, [name]: value });
  };

  const uploadImage = async (value) => {
    let formData = new FormData();
    formData.append('image', value);
    await axios
      .post('http://api.kasapp.in/api/admin/upload', formData)
      .then((res) => {
        if (res.status === 200) {
          setDetail({
            ...categoryDetail,
            categoryImage: res?.data?.data?.imageUrl,
          });
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const getApplicationDetails = (row, edit) => {
    setIsEdit(edit);

    if (edit) {
      setDetail({
        id: row.id,
        isAvailable: row?.isAvailable,
        categoryImage: row.categoryImage,
        categoryName: row.categoryName,
        categoryNameTamil: row.categoryNameTamil,
      });
    } else {
      setDetail({ ...categoryDetail, ...row });
    }
    setModal(true);
  };

  const filterList = (payload) => {
    setQueryPayload({ ...queryPayload, ...payload });
    getCategoryList(payload);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryName: (categoryDetail && categoryDetail.categoryName) || '',
    },
    validationSchema: Yup.object({
      categoryName: Yup.string().required('Please Enter Category Name'),
    }),
    onSubmit: async (values) => {
      let payload = {
        categoryName: values.categoryName,
        categoryImage: categoryDetail.categoryImage,
        categoryNameTamil: 'tamil',
      };
      if (isEdit) {
        payload['id'] = categoryDetail.id;
        await props.updateCategory(payload, categoryDetail.id.toString());
      } else {
        await props.createCategory(payload);
      }
      validation.resetForm();

      setModal(false);
      await props.getCategoryList(queryPayload);
    },
  });

  const toggle = (type) => {
    setDetail({ ...categoryDetail, ...defaultCategoryData });
    setModal(type);
  };

  const getTableColumns = () => {
    let editObj = {
      text: 'Actions',
      formatter: (cellContent, row, rowIndex) => (
        <div className='d-flex align-items-center justify-content-center'>
          <div onClick={() => getApplicationDetails(row, true)}>
            <MdEdit size={18} />
          </div>
        </div>
      ),
    };

    let newtable = [...CategoryTableColumns, editObj];
    return newtable;
  };
  const toggleAvailablity = async (id) => {
    await props.deleteCategory(id);
    setModal(false);
    await props.getCategoryList(queryPayload);
  };
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
        body.classList.remove("sidebar-enable");
    } 

  }

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <CustomBootstrapTable
            rows={categoryList?.list}
            pageMetaData={categoryList?.pageMetaData}
            columns={getTableColumns()}
            isLoading={false}
            keyField={'id'}
            filterList={(...params) => filterList(...params)}
            getApplicationDetails={(row) => getApplicationDetails(row, true)}
            showStatusFilter={false}
            showDateFilter={false}
            history={props.history}
            showSearch={true}
            createBtnLabel='Create Category'
            onCreateRedirect={null}       
            showCreateBtn={true}
            triggerPopup={() =>
              getApplicationDetails(defaultCategoryData, false)
            }
          />
        </Container>
      </div>
      <ModalContainer
        isOpen={isOpen}
        toggle={() => toggle(false)}
        title={isEdit ? 'Edit Category' : 'Create Category'}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className='col-12'>
              <FormikFormInput
                placeholder='Enter Category Name'
                name='categoryName'
                type='text'
                formGroupClassName='mb-3'
                label='Category Name'
                labelClassName='col-form-label'
                validation={validation}
              />

              {/* <FormikFormInput
                placeholder='CategoryName In Tamil'
                name='categoryNameTamil'
                type='text'
                formGroupClassName='mb-3'
                label='Category Name In Tamil'
                labelClassName='col-form-label'
                validation={validation}
              /> */}
            </Col>

            <div>
              <LogoUpload
                title='Upload Category Image'
                name='categoryImage'
                groupClassName='mb-3'
                labelClassName=''
                preview={categoryDetail['categoryImage']}
                onChange={(...params) => onChange(...params)}
                validation={validation}
                removeImage={() =>
                  setDetail({ ...categoryDetail, categoryImage: '' })
                }
              />
              <div className='mt-2 text-muted'>
                ( Note : Upload an image with a <strong>width of 70px</strong> and a{' '}
                <strong>height of 70px</strong> )
              </div>
            </div>

            <div style={{ marginTop: '20px' }}>
              <Label style={{ marginBottom: '10px' }}>
                Is Category Available
              </Label>
              <ToggleSwitch
                label=''
                name='isAvailable'
                id={categoryDetail.id}
                checked={categoryDetail?.isAvailable == 1 ? true : false}
                onChange={(val) => toggleAvailablity(categoryDetail.id)}
              />
            </div>
            <div className='d-flex align-items-center mt-3'>
              <Col>
                <div className='d-flex justify-content-end'>
                  <button type='submit' className='px-4 primary_btn'>
                    Save
                  </button>
                </div>
              </Col>
            </div>
          </Row>

          <Row></Row>
        </Form>
      </ModalContainer>
    </>
  );
};

const mapStateToProps = ({ category, loader }) => {
  return {
    category,
    loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(categoryAction, dispatch);
};

const Categories = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategorieManagementFunc);

export { Categories };
