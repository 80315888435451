import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';

import ReactSelect from 'components/ReactSelect';
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormikFormInput from 'components/FormInput/formikForInput';

import BackBtn from 'components/Button/backBtn';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { API } from 'service/api';
import { API_BOOK } from 'service/endpoints';
// Custom
import * as productAction from 'actions/product';

import axios from 'axios';
import { Toast } from 'helpers/Toast';
import { LogoUpload } from 'components/Uploadfile/LogoUpload';

const CreateProductManagementFunc = (props) => {
  const defaultProductData = {
    productName: '',
    productNameTamil: '',
    tag: '',
    price: '',
    finalPrice: '',
    quantity: '',
    unitType: '',
    categoryId: '',
    description: '',
    descriptionTamil: '',
    productImage: ''
    // status:true
  };
  const {
    match: { params },
  } = props;


  const [isEdit, setEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [productDetail, setDetail] = useState({
    productName: null,
    productNameTamil: null,
    tag: null,
    price: null,
    finalPrice: null,
    quantity: null,
    unitType: null,
    categoryId: null,
    description: null,
    productImage: null
    // status:true
  });

  const [categoryDropdown, setCategoryDropdown] = useState([])

  useEffect(() => {
    getApis();
  }, []);

  const getApis = async () => {
    let categories = await getCategoryList()
    if (params && params.id && !isEdit) {
      await getSingleProductDetail(params.id,categories);
    }
  };
  const getCategoryList = async () => {
    try {
      let requestPayload = {
        ...API_BOOK.MAIN_APP.CATEGORY.CATEGORY_LIST_API,
      }
      let response = await API(requestPayload)
      if (response.status === 201) {
        let categories = response.data.list.map(cat => ({ label: cat.categoryName, value: cat.id }))
        setCategoryDropdown(categories)
        return categories
      }
    } catch (error) {
      Toast('error', error?.response?.data?.message);
    }
  }

  const getSingleProductDetail = async (id,categories) => {
    try {
      let { GET_SINGLE_PRODUCT_DETAIL_API } = API_BOOK.MAIN_APP.PRODUCT;
      let requestPayload = {
        ...GET_SINGLE_PRODUCT_DETAIL_API,
        url: GET_SINGLE_PRODUCT_DETAIL_API.url + `/${id}`
      };

      const res = await API(requestPayload);
      if (res && !_.isEmpty(res.data)) {
        setEdit(true);
        setDetail({
          ...productDetail,
          categoryId: categories.find(ct => ct.value === res.data.categoryId),
          id: res.data.id,
          productName: res.data.productName,
          productNameTamil: res.data.productNameTamil,
          tag: res.data.tag,
          price: res.data.price,
          quantity: res.data.quantity.toString(),
          unitType: res.data.unitType,
          finalPrice: res.data.finalPrice,
          description: res.data.description,
          productImage: res.data.productImage
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
 
  const onChange = (name, value) => {
    if (name === 'productImage') {
      uploadImage(value)
    } else {
      setDetail({...productDetail, [name]: value})
    }
  };


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      productName: (productDetail && productDetail.productName) || '',
      productNameTamil: (productDetail && productDetail.productNameTamil) || '',
      tag: (productDetail && productDetail.tag) || '',
      price: (productDetail && productDetail.price) || '',
      finalPrice: (productDetail && productDetail.finalPrice) || '',
      quantity: (productDetail && productDetail.quantity) || '',
      unitType: (productDetail && productDetail.unitType) || '',
      categoryId: (productDetail && productDetail.categoryId) || '',
      description: (productDetail && productDetail.description) || '',
      productImage: (productDetail && productDetail.productImage) || '',
    },
    validationSchema: Yup.object({
      productName: Yup.string().required('Please Enter product Name'),
      productNameTamil: Yup.string().required('Please Enter product Name in Tamil'),
      productImage: Yup.string().required('Please Choose Product Image'),
    }),
    onSubmit: async (values) => {
      let payload = {
        categoryId: values.categoryId?.value,
        productName: values.productName,
        productNameTamil: values.productNameTamil,
        price: values.price,
        finalPrice: values.finalPrice,
        quantity: values.quantity,
        unitType: values.unitType,
        productImage: values.productImage
      };

      if(values.tag != '') {
        payload = {
          ...payload,
          tag: values.tag
        }
      }
      if (isEdit) {
        payload = {
          ...payload,
          id: productDetail.id,
        }
        await props.updateProduct(payload, productDetail.id);
      } else {
        await props.createProduct(payload);
      }

      validation.resetForm();
      setDetail({ ...productDetail, ...defaultProductData });
    },
  });

 
  const uploadImage = async (value) => {
    let formData = new FormData()
    formData.append('image', value)
  
    try {
      const response = await axios.post('http://api.kasapp.in/api/admin/upload', formData)
      if (response.status === 200) {
        // setDetail({
        //   ...productDetail,
        //   productImage: response?.data?.data?.imageUrl,
        // })
        validation.setFieldValue('productImage', response?.data?.data?.imageUrl)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <>
          <Row>
            <Col xs='12'>
              <div className='page-title-box d-flex'>
                <BackBtn history={props.history} />
                <CardTitle className='h4 mx-2'>{isEdit ? 'Edit' : 'Create'} Product</CardTitle>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col className='col-lg-12'>
                        <>
                          <Row>
                            <Col className='col-12' lg='3' md='3'>
                              <ReactSelect
                                name='categoryId'
                                label='Choose Category'
                                formGroupClassName='mb-3'
                                labelClassName='col-lg-12 col-form-label'
                                placeholder='Select Category'
                                isMulti={false}
                                isClearable={false}
                                closeMenuOnSelect={true}
                                maxLimit={1}
                                value={productDetail['categoryId']}
                                options={categoryDropdown}
                                onChange={(...params) => onChange(...params)}
                              />
                            </Col>
                            <Col className='col-12' lg='3' md='3'>
                              <FormikFormInput
                                placeholder='productName'
                                name='productName'
                                type='text'
                                formGroupClassName='mb-3'
                                label='product Name'
                                labelClassName='col-form-label'
                                value={productDetail['productName']}
                                validation={validation}
                              />
                            </Col>
                            <Col className='col-12' lg='3' md='3'>
                              <FormikFormInput
                                placeholder=' product Name In Tamil'
                                name='productNameTamil'
                                type='text'
                                formGroupClassName='mb-3'
                                label='product Name In Tamil'
                                labelClassName='col-form-label'
                                validation={validation}
                              />
                            </Col>
                            <Col className='col-12' lg='3' md='3'>
                              <FormikFormInput
                                placeholder='quantity'
                                name='quantity'
                                type='number'
                                formGroupClassName='mb-3'
                                label='Quantity'
                                labelClassName='col-form-label'
                                value={productDetail['quantity']}
                                validation={validation}
                              />
                            </Col>
                            <Col className='col-12' lg='3' md='3'>
                              <FormikFormInput
                                placeholder='unitType'
                                name='unitType'
                                type='select'
                                formGroupClassName='mb-3'
                                label='Unit Type'
                                defaultSelect={true}
                                onChange={(...params) => onChange(...params)}
                                options={
                                  [
                                    {
                                      label: 'Kg',
                                      value: 'Kg'
                                    },
                                    {
                                      label: 'Item',
                                      value: 'item'
                                    },
                                    {
                                      label: 'Grams',
                                      value: 'grams'
                                    },
                                    {
                                      label: 'Litre',
                                      value: 'litre'
                                    },
                                    {
                                      label: 'ml',
                                      value: 'ml'
                                    }
                                  ]
                                }
                                labelClassName='col-form-label'
                                value={productDetail['unitType']}
                                validation={validation}
                              />
                            </Col>
                            <Col className='col-12' lg='3' md='3'>
                              <FormikFormInput
                                placeholder='Enter price'
                                name='price'
                                type='text'
                                formGroupClassName='mb-3'
                                label='Striked Price'
                                labelClassName='col-form-label'
                                validation={validation}
                              />
                            </Col>
                            <Col className='col-12' lg='3' md='3'>
                              <FormikFormInput
                                placeholder='Enter price'
                                name='finalPrice'
                                type='text'
                                formGroupClassName='mb-3'
                                label='Final Price'
                                labelClassName='col-form-label'
                                validation={validation}
                              />
                            </Col>
                            <Col className='col-12' lg='3' md='3'>
                              <FormikFormInput
                                placeholder='tag'
                                name='tag'
                                type='select'
                                formGroupClassName='mb-3'
                                label='Tag'
                                defaultSelect={true}
                                onChange={(...params) => onChange(...params)}
                                options={
                                  [
                                    {
                                      label: 'Top Selling',
                                      value: 'TOP_SELLING'
                                    },
                                    {
                                      label: 'Best Selling',
                                      value: 'BEST_SELLING'
                                    },
                                    {
                                      label: 'New Arrivals',
                                      value: 'NEW_ARRIVALS'
                                    },
                                  ]
                                }
                                labelClassName='col-form-label'
                                value={productDetail['unitType']}
                                validation={validation}
                              />
                            </Col>
                            <Col className='col-12' lg='4' md='4'>
                              <FormikFormInput
                                placeholder='Description'
                                name='description'
                                type='textarea'
                                formGroupClassName='mb-3'
                                label='description'
                                labelClassName='col-form-label'
                                value={productDetail['description']}
                                validation={validation}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className='col-12' lg='3' md='3'>
                              <LogoUpload
                                title="Choose Product Image"
                                name="productImage"
                                groupClassName=""
                                labelClassName=""
                                preview={validation.values['productImage']}
                                onChange={(...params) => onChange(...params)}
                                validation={validation}
                                removeImage={() => setDetail({ ...productDetail, productImage: '' })}
                              />
                               <div className="mt-2 text-muted">
                                ( Note : Upload an image with a <strong>width of 70px</strong> and a <strong>height of 70px</strong> )
                               </div>
                            </Col>
                          </Row>
                        </>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className='d-flex justify-content-end'>
                          <button type='submit' className='px-4 primary_btn' >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      </Container>
    </div>
  );
};
const mapStateToProps = ({ product, loader }) => {
  return {
    product,
    loader,

  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(productAction, dispatch);
};

const CreateProduct = connect(mapStateToProps, mapDispatchToProps)(CreateProductManagementFunc)

export { CreateProduct };
