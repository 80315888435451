import { combineReducers } from "redux"

// Front
import Layout from "./layout/layout"

import profile from './profile'
import category from "./category"
import product from "./product"
import admin from "./admin"
import customer from "./customer"
import order from "./order"
import banner from "./banner"
import dashboard from './dashboard';

const rootReducer = combineReducers({
  Layout,

  profile,
  admin,
  category,
  product,
  customer,
  order,
  banner,
  dashboard,

})

export default rootReducer
