import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
// import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/placeholder.png";
import { history, logout } from "helpers/utils";

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState(props?.profile?.name);

  const lockscreen = () => {
    history.push('/auth/lockscreen')
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-inline-block ms-2 me-1 ">{username}</span>
          <i className="mdi mdi-chevron-down d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
         
          {/* <DropdownItem tag="a" href="#" onClick={() => lockscreen()}>
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}
          <Link to="#" className="dropdown-item" onClick={() => logout()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};


const mapStatetoProps = ({profile}) => {
  return { 
    profile: profile
  };
};

export default withRouter(
  connect(mapStatetoProps, {})(ProfileMenu)
);
