import React from "react";
import ReactDOM from 'react-dom/client';
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import {store} from "./service/store";
import Routes from "routes";
import './assets/scss/theme.scss'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <Routes />
    </Provider>
);

serviceWorker.unregister()