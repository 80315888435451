import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Label,
  Col,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import FormInput from 'components/FormInput';
import FormikFormInput from 'components/FormInput/formikForInput';
import _ from 'lodash';
import { API } from 'service/api';
import { API_BOOK } from 'service/endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
// custom
import ReactSelect from 'components/ReactSelect';
import Close from 'assets/images/close.svg'
import './count.scss';

const OffcanvasComponent = ({
  isRight,
  setIsRight,
  selectedOrderId,
  getOrderList,
  setSelectedOrderId
}) => {
  const [orderDetail, setOrderDetail] = useState({});
  const [activeTab, setActiveTab] = useState('1');
  const [productList, setProductList] = useState([]);
  const [deliveryPartners, setDeliveryPartners] = useState([]);

  useEffect(() => {
    const fetchOrderDetail = async (id) => {
      try {
        let { GET_ORDER_BY_ID_API } = API_BOOK.MAIN_APP.ORDER;
        const requestPayload = {
          ...GET_ORDER_BY_ID_API,
          url: GET_ORDER_BY_ID_API.url + `/${id}`,
        };
        const res = await API(requestPayload);
        let newres = {
          ...res.data,
          orderDetails: res.data.orderDetails.map(order => ({ ...order, selectedProduct: { label: order.productName, value: order.productId } }))
        }
        setOrderDetail(newres);
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrderDetail(selectedOrderId)

  }, [selectedOrderId,]);

  useEffect(() => {
    const fetchProductList = async () => {
      try {
        let { GET_PRODUCT_LIST_API } = API_BOOK.MAIN_APP.ORDER;
        const requestPayload = { ...GET_PRODUCT_LIST_API };
        const res = await API(requestPayload);
        setProductList(res.data.list);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProductList();
  }, []);


  useEffect(() => {
    const fetchDeliveryPartners = async () => {
      try {
        let { GET_DELIVER_PARTNERS_API } = API_BOOK.MAIN_APP.ORDER;
        const requestPayload = {
          ...GET_DELIVER_PARTNERS_API,
          params: {
            page: 1,
            limit: 100,
          },
        };
        const res = await API(requestPayload);
        setDeliveryPartners(res.data.list);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDeliveryPartners();
  }, []);

  const onChange = (name, value) => {
    setOrderDetail({ ...orderDetail, [name]: value });
  };

  const onChangeOrderEdit = (name, value, index) => {
    const newOrderDetails = [...orderDetail.orderDetails];

    let getProduct = productList.find(pr => pr.value == value.value)
    newOrderDetails[index]['selectedProduct'] = value
    newOrderDetails[index]['quantity'] = 1
    newOrderDetails[index]['unit'] = getProduct.quantity
    newOrderDetails[index]['price'] = getProduct.finalPrice
    newOrderDetails[index]['unitType'] = getProduct.unitType
    newOrderDetails[index]['productId'] = getProduct.value
    newOrderDetails[index]['subTotal'] = parseFloat(getProduct.finalPrice)

    setOrderDetail({ ...orderDetail, orderDetails: newOrderDetails });
  };


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: (orderDetail && orderDetail.status) || '',
      salesPersonId: (orderDetail && orderDetail.salesPersonId) || '',
    },
    validationSchema: Yup.object({
      status: Yup.string().required('Status is required'),
      salesPersonId: Yup.string().required('salesPersonId is required'),
    }),
    onSubmit: async (values) => {
      try {
        let { UPDATE_ORDER_API } = API_BOOK.MAIN_APP.ORDER;
        const requestPayload = {
          ...UPDATE_ORDER_API,
          url: UPDATE_ORDER_API.url + `/${selectedOrderId}`,
          data: {
            ...values,
            status: parseInt(values.status)
          },
        };
        const res = await API(requestPayload);
        if (res.status === 200) {
          getOrderList();
          setSelectedOrderId(null)
          setIsRight(false);
        }
      } catch (error) {
        console.log('Error updating order:', error);
      }

      // validation.resetForm();
    },
  });

  const editOrder = async () => {
    try {
      let { EDIT_ORDER_API } = API_BOOK.MAIN_APP.ORDER;
      console.log('orderDetail.orderDetails',orderDetail)
      const requestPayload = {
        ...EDIT_ORDER_API,
        url: EDIT_ORDER_API.url + `/${selectedOrderId}`,
        data: {
          orderAddress: orderDetail.orderAddress,
          deliveryCharge: orderDetail.deliveryCharge,
          orderItems: orderDetail.orderDetails.map(it => ({ id: it.id, productId: it.productId, quantity: it.quantity }))
        },
      };
      const res = await API(requestPayload);
      if (res.status === 200) {
        getOrderList();
        setSelectedOrderId(null)
        setIsRight(false);
      }
    } catch (error) {
      console.log('Error updating order:', error);
    }
  }

  const handleUnitIncrease = (index) => {
    const newOrderDetails = [...orderDetail.orderDetails];
    const product = newOrderDetails[index];
    product.quantity = product.quantity + 1
    product.subTotal = parseFloat(product.subTotal) + parseFloat(product.price)
    setOrderDetail({ ...orderDetail, orderDetails: newOrderDetails });
  };

  const handleUnitDecrement = (index) => {
    const newOrderDetails = [...orderDetail.orderDetails];
    const product = newOrderDetails[index];
    product.quantity = product.quantity - 1
    product.subTotal = product.subTotal - product.price
    if (product.quantity == 0) {
      handleRemoveRow(index)
    } else {
      setOrderDetail({ ...orderDetail, orderDetails: newOrderDetails });
    }
  };

  const handleRemoveRow = (index) => {
    const updatedOrderDetails = [...orderDetail.orderDetails];
    updatedOrderDetails.splice(index, 1);
    setOrderDetail({ ...orderDetail, orderDetails: updatedOrderDetails });
  };

  const addItem = () => {
    const updatedOrderDetails = [...orderDetail.orderDetails];
    updatedOrderDetails.push({
      orderId: orderDetail.id,
      price: 0,
      productId: '',
      productName: '',
      quantity: 0,
      selectedProduct: null,
      subTotal: 0,
      unit: '',
      unitType: ''
    })
    setOrderDetail({ ...orderDetail, orderDetails: updatedOrderDetails });
  }

  return (
    <Offcanvas
      isOpen={isRight}
      direction='end'
      toggle={() => {
        setIsRight(false)
        setSelectedOrderId(null)
      }}
      style={{ width: '700px' }}
    >
      <OffcanvasHeader
        className='offcanvas-header'
        toggle={() => {
          setIsRight(false)
          setSelectedOrderId(null)
        }}
      ></OffcanvasHeader>
      <Nav tabs className='nav-tabs-custom nav-justified mt-auto'>
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={activeTab === '1' ? 'active' : ''}
            onClick={() => {
              toggle('1');
            }}
          >
            ORDER DETAILS
          </NavLink>

        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '2' ? 'active' : ''}
            onClick={() => {
              toggle('2');
            }}
          >
            EDIT ORDER
          </NavLink>
        </NavItem>
      </Nav>

      <OffcanvasBody>
        <TabContent activeTab={activeTab}>
          <TabPane tabId='1'>
            <Row>
              <Col>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col lg={6}>
                      <FormInput
                        type='text'
                        label={`Order ID`}
                        name='orderId'
                        disabled={true}
                        value={orderDetail.id}
                      />
                      <FormInput
                        type='text'
                        label={`Total Amount`}
                        name='totalAmount'
                        disabled={true}
                        value={orderDetail.totalAmount}
                      />
                      <FormInput
                        type='detail'
                        label={`Product Details`}
                        name='productdetails'
                        disabled={true}
                        details={orderDetail.orderDetails}
                      />
                      <FormikFormInput
                        placeholder='Status'
                        name='status'
                        type='select'
                        formGroupClassName='mb-3'
                        label='Status'
                        defaultSelect={true}
                        onChange={(...params) => onChange(...params)}
                        options={[
                          {
                            label: 'CREATED',
                            value: '1',
                          },
                          {
                            label: 'CONFIRMED',
                            value: '2',
                          },
                          {
                            label: 'PICKED UP',
                            value: '3',
                          },
                          {
                            label: 'DELIVERED',
                            value: '4',
                          },
                          {
                            label: 'CANCELLED',
                            value: '5',
                          },
                          {
                            label: 'RETURN ORDER',
                            value: '6',
                          },
                        ]}
                        labelClassName='col-form-label'
                        value={orderDetail.status}
                        validation={validation}
                      />
                      <FormInput
                        type='text'
                        label={`Order Amount`}
                        name='orderAmount'
                        disabled={true}
                        value={orderDetail.orderAmount}
                      />
                      <FormInput
                        type='text'
                        label={`Delivery Charge`}
                        name='deliveryCharge'
                        disabled={true}
                        value={orderDetail.deliveryCharge}
                      />

                    </Col>
                    <Col lg={6}>
                      <FormInput
                        type='text'
                        label={`Customer Name`}
                        name='userName'
                        disabled={true}
                        value={
                          orderDetail?.customerDetails?.userName
                        }
                      />
                      <FormInput
                        type='text'
                        label={`Customer Number`}
                        name='userName'
                        disabled={true}
                        value={
                          orderDetail?.customerDetails?.mobile
                        }
                      />
                      <FormInput
                        type='textarea'
                        label={`Customer Address`}
                        name='userName'
                        disabled={true}
                        value={`${orderDetail?.orderAddress?.addressLine1} ${orderDetail.orderAddress?.addressLine2}, ${orderDetail?.orderAddress?.landmark}, ${orderDetail?.orderAddress?.postalCode} `}
                      />
                      <FormikFormInput
                        placeholder='Select...'
                        name='salesPersonId'
                        type='select'
                        formGroupClassName='mb-3'
                        label='Choose Delivery Person'
                        defaultSelect={true}
                        onChange={(...params) => onChange(...params)}
                        options={deliveryPartners}
                        labelClassName='col-form-label'
                        value={orderDetail.salesPersonId}
                        validation={validation}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-6">
                      <Button
                        color='primary w-100 border-none'
                        className='fw-600 mb-3 mt-3'
                        type='submit'
                      >
                        Confirm
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId='2'>
            <Form>
              <Col>
                {orderDetail && orderDetail.orderDetails && orderDetail.orderDetails.map((product, index) => (
                  <Row key={index} className='no-gutters '>
                    <Col className='col-1 align-self-center'>
                      <div className='mb-1'>{index + 1}.</div>
                    </Col>
                    <Col className='col-4 pt-1'>
                      <ReactSelect
                        name='selectedProduct'
                        label='Product'
                        formGroupClassName=''
                        labelClassName='col-lg-12 col-form-label'
                        placeholder='Select Product'
                        isMulti={false}
                        isClearable={false}
                        closeMenuOnSelect={true}
                        maxLimit={1}
                        value={product['selectedProduct']}
                        options={productList}
                        spanText={`${product.unit != '' ?
                          `( ${product.unit} ${product.unitType} - Rs. ${product.price} )`
                          :
                          ""
                          }`
                        }
                        onChange={(...params) => onChangeOrderEdit(...params, index)}
                      />
                    </Col>
                    <Col className='col-3 pt-1'>
                      <Label>Items</Label>
                      <div className='justify-space-evenly align-items-center d-flex order_edit_inc_dec'>
                        <FontAwesomeIcon
                          icon={faMinus}
                          color={'#60b246'}
                          fontSize={18}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleUnitDecrement(index)}
                        />
                        <p className='product_count'>{product.quantity}</p>
                        <FontAwesomeIcon
                          icon={faPlus}
                          color={'#60b246'}
                          fontSize={18}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleUnitIncrease(index)}
                        />
                      </div>
                    </Col>
                    <Col className='col-3 pt-1'>
                      <FormInput
                        type='text'
                        label={`Price`}
                        name='totalAmount'
                        formGroupClassName=""
                        disabled={true}
                        value={product.subTotal}
                      />
                    </Col>
                    <Col className='col-1 align-self-center close-icon-wrapper mb-3'>
                      <img className='filter-out-img close-icon ' src={Close} alt='Close' onClick={() => handleRemoveRow(index)}></img>

                    </Col>
                  </Row>
                ))}
                <div className='text-right'>
                  <Button className='cancel-button' color='link' onClick={() => addItem()}>
                    Add Item
                  </Button>
                </div>
                <Row className="no-gutters justify-content-center">
                  <Col lg="5" md="10" sm="12" className="mt-3">
                    <Button
                      color="primary w-100 border-none"
                      className="fw-600"
                      onClick={() => editOrder()}
                    >
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Form>


          </TabPane>

        </TabContent>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export { OffcanvasComponent };