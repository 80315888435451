let endpoints = {
  UPLOAD_DOC: '/v1/fileUpload',

  LOGIN: '/v1/user/admin/login',
  VERIFY_TOKEN: '/api/auth/verify/authorization',
  GET_BOOKING_DETAIL: '/api/admin/bookings/list',

  // DASHBOARD
  TOP_CUSTOMERS_AND_COUNTS_API: 'v1/dashboard/analytics',
  YEARLY_ORDER_STATS: 'v1/order/analytics',

  //CATEGORY
  CATEGORY_LIST: '/v1/category',
  CREATE_CATEGORY: '/v1/category',
  UPDATE_CATEGORY: '/v1/category',
  DELETE_CATEGORY: '/v1/category',

  // PRODUCT
  PRODUCT_LIST: '/v1/all/product',
  CREATE_PRODUCT: "/v1/product",
  UPDATE_PRODUCT: "/v1/product",
  DELETE_PRODUCT: "/v1/product",
  GET_SINGLE_PRODUCT_DETAIL: '/v1/product',

  // USER MANAGEMENT
  ADMINS_LIST: '/v1/user/admin/user/List',
  CREATE_ADMIN_API: '/v1/user/register/admin',
  GET_ADMIN_BY_ID: '/v1/user/admin',
  UPDATE_ADMIN_API: '/v1/user/admin',
  ROLES_LIST: '/api/admin/roles/list',
  DELETE_ADMIN: '/v1/user/admin',

  // CUSTOMER 
  CUSTOMER_LIST_API: "/v1/user/customer/user/List?userName",

  // ORDER
  ORDER_LIST: '/v1/order/list',
  GET_ORDER_BY_ID: '/v1/order/orderDetail',
  UPDATE_ORDER: '/v1/order/status',
  EDIT_ORDER: '/v1/order/updateOrder',
  GET_PRODUCT_LIST_API: '/v1/master/product',
  GET_DELIVERY_PARTNERS: "/v1/user/deliveryPerson",
  DELIVERY_PARTNER_ORDERS_LIST: "/v1/user/deliveryPerson/orders",
  // DELIVERYBOY
  GET_OPEN_ORDER:'/v1/user/deliveryPerson/orders',
  GET_COMPLETED_ORDER:'/v1/user/deliveryPerson/orders?orderStatus',
  
  //  BANNER
  BANNER: '/v1/banner',
  
  GET_AVAILABLE_DELIVERY_BOYS: '/api/admin/deliveryboy/available/list',
  

  

};

export let API_BOOK = {
  ADMIN_MANAGEMENT: {
    SESSION_API: {
      LOGIN_API: {
        url: endpoints.LOGIN,
        method: 'POST',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      }
    },
  },
  MAIN_APP: {
    DASHBOARD : {
      TOP_CUSTOMERS_AND_COUNTS_API: {
        url: endpoints.TOP_CUSTOMERS_AND_COUNTS_API,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      YEARLY_ORDER_STATS_API: {
        url: endpoints.YEARLY_ORDER_STATS,
        method: 'POST',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      }
    },
    COMMON: {
      UPLOAD_DOC_API: {
        url: endpoints.UPLOAD_DOC,
        method: 'POST',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      }
    },
    CATEGORY: {
      CATEGORY_LIST_API: {
        url: endpoints.CATEGORY_LIST,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      CREATE_CATEGORY_API: {
        url: endpoints.CREATE_CATEGORY,
        method: 'POST',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      UPDATE_CATEGORY_API: {
        url: endpoints.UPDATE_CATEGORY,
        method: 'PUT',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      DELETE_CATEGORY: {
        url: endpoints.DELETE_CATEGORY,
        method: 'PUT',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },

    },
    PRODUCT: {
      PRODUCT_LIST_API: {
        url: endpoints.PRODUCT_LIST,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      CREATE_PRODUCT_API: {
        url: endpoints.CREATE_PRODUCT,
        method: 'POST',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      UPDATE_PRODUCT_API: {
        url: endpoints.UPDATE_PRODUCT,
        method: 'PUT',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      DELETE_PRODUCT: {
        url: endpoints.DELETE_PRODUCT,
        method: 'PUT',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      GET_SINGLE_PRODUCT_DETAIL_API: {
        url: endpoints.GET_SINGLE_PRODUCT_DETAIL,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      }

    },
    // USER MANAGENT
    ADMIN: {
      ADMINS_LIST_API: {
        url: endpoints.ADMINS_LIST,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },
      ROLES_LIST_API: {
        url: endpoints.ROLES_LIST,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },
      GET_ADMIN_BY_ID: {
        url: endpoints.GET_ADMIN_BY_ID,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },
      DELETE_ADMIN: {
        url: endpoints.DELETE_ADMIN,
        method: 'PUT',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      CREATE_ADMIN_API: {
        url: endpoints.CREATE_ADMIN_API,
        method: 'POST',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },
      UPDATE_ADMIN_API: {
        url: endpoints.UPDATE_ADMIN_API,
        method: 'PUT',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },
    },

    // CUSTOMER
    CUSTOMER: {
      CUSTOMER_LIST_API: {
        url: endpoints.CUSTOMER_LIST_API,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },
    },
    ORDER: {
      ORDER_LIST_API: {
        url: endpoints.ORDER_LIST,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      UPDATE_ORDER_API: {
        url: endpoints.UPDATE_ORDER,
        method: 'PUT',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      EDIT_ORDER_API: {
        url: endpoints.EDIT_ORDER,
        method: 'PUT',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      GET_ORDER_BY_ID_API: {
        url: endpoints.GET_ORDER_BY_ID,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      GET_DELIVER_PARTNERS_API : {
        url: endpoints.GET_DELIVERY_PARTNERS,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      GET_PRODUCT_LIST_API: {
        url: endpoints.GET_PRODUCT_LIST_API,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      DELIVERY_PARTNER_ORDERS_LIST_API: {
        url: endpoints.DELIVERY_PARTNER_ORDERS_LIST,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL',
      },
      GET_OPEN_ORDER_API: {
        url: endpoints.GET_OPEN_ORDER,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },
      GET_COMPLETED_ORDER_API: {
        url: endpoints.GET_COMPLETED_ORDER,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },    
    },
    // BANNER
    BANNER: {
      BANNER_LIST_API: {
        url: endpoints.BANNER,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },
      CREATE_OR_UPDATE_BANNER_API: {
        url: endpoints.BANNER,
        method: 'POST',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },
    },
    // DELIVERYBOY
     DELIVERYBOY :{
      GET_OPEN_ORDER_API: {
        url: endpoints.GET_OPEN_ORDER,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },
      GET_COMPLETED_ORDER_API: {
        url: endpoints.GET_COMPLETED_ORDER,
        method: 'GET',
        data: null,
        headers: {},
        baseUrlType: 'BASE_URL'
      },
     
     }
  },
  
};