import React from "react";
import "./ToggleSwitch.css";
  
const ToggleSwitch = ({ id, name,label, onChange, checked }) => {
  return (
    <div className="container">
      {label}{" "}
      <div className="toggle-switch">
        <input 
            type="checkbox" 
            className="checkbox" 
            name={name} 
            id={id} 
            checked={checked}
            onChange={e => onChange(e.target.checked)}
        />
        <label className="label" htmlFor={id}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};
  
export default ToggleSwitch;