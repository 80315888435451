import { customer } from 'service/actionType'
import { API } from 'service/api'
import { API_BOOK } from 'service/endpoints'
import { Toast } from 'helpers/Toast'
import _ from 'lodash'


let { CUSTOMER_LIST_API } = API_BOOK.MAIN_APP.CUSTOMER
let {
    IS_CUSTOMER_LIST_FETCHING,
    

    CUSTOMER_LIST
} = customer.CUSTOMER

let sampleListData = {
    data: [],
     pageMetaData: {
        count: 0,
        limit: 10,
        page: 1,
        totalPages: 0
    }
}

export const getCustomerList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: IS_CUSTOMER_LIST_FETCHING, payload:  true })
        let requestPayload = {
            ...CUSTOMER_LIST_API,
            params: {
                ...queryPayload
            }
        }
        let response = await API(requestPayload)
        if(response.status == 200 ) {
            dispatch({ type: CUSTOMER_LIST, payload: response.data})
            dispatch({type:  IS_CUSTOMER_LIST_FETCHING, payload:false})
        } else {
            dispatch({ type: CUSTOMER_LIST, payload: sampleListData})
            dispatch({type:  IS_CUSTOMER_LIST_FETCHING, payload:false})
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message);
    }
}

