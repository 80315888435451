import { customer } from 'service/actionType'
const initialState = {
    customerList: {
        list: [],
        pageMetaData: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        }
    }
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case customer.CUSTOMER.CUSTOMER_LIST:
            return {
                ...state,
                customerList: {
                    list: payload.list,
                    pageMetaData: payload. pageMetaData
                }
            }
        default:
            return state
    }

}