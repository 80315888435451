import {order} from 'service/actionType'
const initialState = {
    orderList: {
        list: [],
        pageMetaData: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        }
    },
    isDeliveryPartnerListFetching: false,
    deliveryPartnerAssignedOrders: [],
    deliveryPartnerDeliveredOrders: {
        list: [],
        pageMetaData: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        }
    },
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
       
        case order.ORDER.ORDER_LIST:
            return {
                ...state,
                orderList: {
                    list: payload.list,
                    pageMetaData: payload.pageMetaData
                }
            }

        case order.ORDER.IS_DELIVERY_PARTNER_LIST_FETCHING : 
            return {
                ...state,
                isDeliveryPartnerListFetching: payload
            }
        case order.ORDER.DELIVERY_PARTNER_ASSIGNED_ORDERS:
            return {
                ...state,
                orderList: {
                    list: payload.list,
                    pageMetaData: payload.pageMetaData
                }
            }
        case order.ORDER.DELIVERY_PARTNER_DELIVERED_ORDERS:
            return {
                ...state,
                deliveryPartnerDeliveredOrders: {
                    list: payload.list,
                    pageMetaData: payload.pageMetaData
                }
            }
        default:
            return state
    }

}