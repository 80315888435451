import { product } from 'service/actionType'
import { API } from 'service/api'
import {API_BOOK} from 'service/endpoints'
import {Toast} from 'helpers/Toast'

    
let { PRODUCT_LIST_API,CREATE_PRODUCT_API,UPDATE_PRODUCT_API,DELETE_PRODUCT} = API_BOOK.MAIN_APP.PRODUCT
let { 
    IS_PRODUCT_LIST_FETCHING,
    IS_PRODUCT_DETAIL_FETCHING,
     
    PRODUCT_LIST
} = product.PRODUCT


export const getProductList = (queryPayload) => async (dispatch, getState) => {
    try {
        
        dispatch({ type: IS_PRODUCT_LIST_FETCHING, payload:  true })
        let requestPayload = {
            ...PRODUCT_LIST_API,
            params: {
                ...queryPayload
            }
        }
        let response = await API(requestPayload)
        if(response.status == 201){
            dispatch({ type: PRODUCT_LIST, payload: response.data})
            dispatch({type: IS_PRODUCT_LIST_FETCHING, payload:false})
        } else {
            Toast('error', response?.data?.message)
            dispatch({type: IS_PRODUCT_LIST_FETCHING, payload:false})
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message)
    }
}

export const createProduct = (payload) => async (dispatch, getState) => {
    try {
        dispatch({type: IS_PRODUCT_DETAIL_FETCHING, payload:true})
        let requestPayload = {
            ...CREATE_PRODUCT_API,
            data: payload
        }
        let response = await API(requestPayload)
        if(response.status == 200){
            dispatch({type: IS_PRODUCT_DETAIL_FETCHING, payload:false})
            Toast('success', "Successfully Created")

        } else {
            Toast('error', response?.data?.message)
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message)
    }
}

export const updateProduct = (payload,id) => async (dispatch, getState) => {
    try {
        dispatch({type: IS_PRODUCT_DETAIL_FETCHING, payload:true})
        let requestPayload = {
            ...UPDATE_PRODUCT_API,
            url: `${UPDATE_PRODUCT_API.url}/${id}`,
            data: payload
        }
        let response = await API(requestPayload)
        if(response.status == 201){
            dispatch({type: IS_PRODUCT_DETAIL_FETCHING, payload:false})
            Toast('success', "Successfully Updated")
        } else {
            dispatch({type: IS_PRODUCT_DETAIL_FETCHING, payload:false})
            Toast('error', response?.data?.message)
        }
    } catch (error) {
        dispatch({type: IS_PRODUCT_DETAIL_FETCHING, payload:false})
        Toast('error', error?.response?.data?.message)
    }
}

export const deleteProduct = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: IS_PRODUCT_DETAIL_FETCHING, payload:  true })
        let requestPayload = {
            ...DELETE_PRODUCT,
            url: `${DELETE_PRODUCT.url}/status/${id}`,
        }
        let response = await API(requestPayload)
        if(response.status == 201){
            dispatch({type: IS_PRODUCT_DETAIL_FETCHING, payload:false})
            Toast('success', "Successfully Updated")
        } else {
            Toast('error', response?.data?.message)
            dispatch({type: IS_PRODUCT_DETAIL_FETCHING, payload:false})
        }
    } catch (error) {
        dispatch({type: IS_PRODUCT_DETAIL_FETCHING, payload:false})
        Toast('error', error?.response?.data?.message)
    }
}
