
import { loaders, onboard, main, dashboard } from 'service/actionType'
import { API } from 'service/api'
import { API_BOOK } from 'service/endpoints'
import { Toast } from '../helpers/Toast'

let {  TOP_CUSTOMERS_AND_COUNTS_API, YEARLY_ORDER_STATS_API } = API_BOOK.MAIN_APP.DASHBOARD
let {
    TOP_CUSTOMERS_AND_DASHBOARD_COUNTS,
    YEARLY_ORDER_STATS,
} = dashboard

export const getDashboardCountsAndTopCustomers = () => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.DASHBOARD_COUNTS_FETCHING, payload: true })
        let requestPayload = {
            ...TOP_CUSTOMERS_AND_COUNTS_API,
        }
        let response = await API(requestPayload)
        if (response.status === 200) {
            dispatch({ type:  TOP_CUSTOMERS_AND_DASHBOARD_COUNTS, payload: response.data })
            dispatch({ type: loaders.DASHBOARD_COUNTS_FETCHING, payload: false })
        } else {
            Toast({ type: 'error', message: response.data.message })
            dispatch({ type: loaders.DASHBOARD_COUNTS_FETCHING, payload: false })
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message)
        dispatch({ type: loaders.DASHBOARD_COUNTS_FETCHING, payload: false })
    }
}

export const getYearlyOrderStats = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.DASHBOARD_ORDER_STATS_FETCHING, payload: true })
        let requestPayload = {
            ...YEARLY_ORDER_STATS_API,
            data: {
                ...queryPayload
            }
        }
        let response = await API(requestPayload)
        if (response.status === 200) {
            dispatch({ type: YEARLY_ORDER_STATS, payload: response.data })
            dispatch({ type: loaders.DASHBOARD_ORDER_STATS_FETCHING, payload: false })
        } else {
            Toast({ type: 'error', message: response.data.message })
            dispatch({ type: loaders.DASHBOARD_ORDER_STATS_FETCHING, payload: false })
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message)
    }
}



export const topCustomers = () => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.DASHBOARD_TOP_CUSTOMERS_FETCHING, payload: true })
        let { TOP_CUSTOMERS_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...TOP_CUSTOMERS_API
        }
        let response = await API(requestPayload)
        if (response.status === 200) {
            dispatch({ type: main.TOP_CUSTOMERS, payload: response.data.data.data.topCustomers })
            dispatch({ type: loaders.DASHBOARD_TOP_CUSTOMERS_FETCHING, payload: false })
        } else {
            Toast({ type: 'error', message: response.data.message })
            dispatch({ type: loaders.DASHBOARD_TOP_CUSTOMERS_FETCHING, payload: false })
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message)
    }
} 
