import React, { useEffect, useState } from 'react';
import {
  Container,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import * as reportAction from "actions/report";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactSelect from 'components/ReactSelect';

const Reports = (props) => {
 

  return (
    <>
      <div className='page-content'>
        <Container fluid>
         Hello

        </Container>
      </div>
    </>
  );
};




export { Reports }