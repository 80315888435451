import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useState} from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

// const options = {
//   chart: {
//     type: "area",
//     toolbar: {
//       show: true,
//       tools: {
//         zoom: false,
//         zoomin: true,
//         zoomout: true,
//         download: true,
//         selection: false,
//         pan: false,
//         reset: false
//       }
//     },
//   },
//   colors: [ "#f1b44c","#556ee6"],
//   dataLabels: {
//     enabled: false,
//   },
//   stroke: {
//     curve: "smooth",
//     width: 2,
//   },
//   fill: {
//     type: "gradient",
//     gradient: {
//       shadeIntensity: 1,
//       inverseColors: true,
//       opacityFrom: 0.45,
//       opacityTo: 0.05,
//       stops: [20, 100, 100, 100],
//     },
//   },
//   xaxis: {
//     categories: [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec",
//     ],
//   },

//   markers: {
//     size: 3,
//     strokeWidth: 3,

//     hover: {
//       size: 4,
//       sizeOffset: 2,
//     },
//   },
//   legend: {
//     position: "top",
//     horizontalAlign: "right",
//   },
// }
const options = {
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 3,
  },
  chart: {
    toolbar: {
      show: true,
      tools: {
        zoom: true,
        zoomin: true,
        zoomout: true,
        download: true,
        selection: true,
        pan: true,
        reset: false
      }
    },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
          enabled: true,
          delay: 150
      },
      dynamicAnimation: {
          enabled: true,
          speed: 350
      },
      initialAnimation: {
        enabled: true
      }
    }
  },
  plotOptions: {
    area: {
        fillTo: 'origin',
    }
  },
  noData: {
    text: "No data Found",
    align: "center",
    verticalAlign: "middle",
    style: {
      color: "#7f7575",
      fontSize: '14px',
      fontFamily: 'Poppins',
      fontWeight: '500'
    }
  },
  xaxis: {
    sorted: true,
    type: 'category',
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    "title": {
      "text": 'Month',
      "style": {
          cssClass: 'x_axis_label'
      },
      offsetY: 90
    },
    labels: {
      trim: true,
      formatter: (val) => {
        return val
      },
      style: {
        color: '#000000',
        cssClass: 'x_axis_content'
      },
      "trim": true,
      "offsetY": -2
    },
    tooltip: {
      enabled: true,
      offsetY: 100,
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#78909C',
      width: 6,
      offsetX: 0,
      offsetY: 0
    },
    axisBorder: {
      show: true,
      color: '#78909C',
      offsetX: -5,
      offsetY: -1
    }
  },
  yaxis: {
    labels: {
      "offsetX": -4
    },
    title: {
      text: 'Amount',
      style: {
        color: '#000000',
        cssClass: 'y_axis_label'
      }
    },
    axisBorder: {
      show: true,
      color: '#78909C',
      offsetX: 0,
      offsetY: -2
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#78909C',
      width: 6,
      offsetX: 0,
      offsetY: 0.5
    },
  },
  grid: {
    show: true,
    borderColor: "#E0E0E0",
    strokeDashArray: 3,
    padding: {
      left: 10,
      bottom: 0
    }
  },
  tooltip: {
    y: {
      formatter: (val) => {
        return val
      },
    },
  },
  markers: {
    size: 1,
    strokeColors: '#000000',
    strokeWidth: 2,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
    offsetY: 30,
  },
}
export const OverallOrdersGraph = props => {
  const [value, setYear] = useState(new Date())
  const [revenue] = useState(props?.ordersStats?.revenue)
    
  const onChange = (value) => {
    setYear(value)
    props.onYearChange(moment(value).format('yyyy'))
  }
    return (
        <Card>
          <CardBody>
            <div className="d-flex justify-space-between align-items-center flex-wrap">
              <h5 className="card-title m-0">Overall Orders Stats</h5>
              <div className="datepicker_type_1">
                <DatePicker 
                  type="string"
                  showYearPicker={true}
                  onChange={onChange} 
                  selected={value} 
                  dateFormat="yyyy"
                  placeholderText="select year"
                />
              </div>
            </div>
            <div id="area-chart" dir="ltr" className="mt-2">
              <ReactApexChart
                options={options}
                series={[
                  {
                    name: "No Of Orders",
                    data: props?.ordersStats?.orders,
                  },
                  {
                    name: "Revenue",
                    data: props?.ordersStats?.revenue,
                  },
                ]}
                type="area"
                height={350}
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
    )
}