// utils
import { history } from 'helpers/utils'

import {MdDashboardCustomize,MdProductionQuantityLimits,MdPayments} from 'react-icons/md'
import {BsPeopleFill,BsFillBookmarkFill} from 'react-icons/bs'
import {RiFileList3Fill,RiAdminFill} from 'react-icons/ri'
import{FaTable} from 'react-icons/fa'

let roleBasedAllLinks = {
    1 : [
      {
        name: "Dashboard",
        icon: <MdDashboardCustomize size={20} />,
        routeName: "/dashboard",
        togglerName: "/dashboard",
        pathname: 'dashboard',
      },
      {
        name: "Orders",
        icon: <RiFileList3Fill size={20} />,
        routeName: "/orders",
        togglerName: "/orders",
        pathname: 'orders',
      },{
        name: "Customers",
        icon: <BsPeopleFill size={20} />,
        routeName: "/customers",
        togglerName: "/customers",
        pathname: 'customers',
      },
      {
        name: "Categories",
        icon: <BsFillBookmarkFill size={20} />,
        routeName: "/categories",
        togglerName: '/categories',
        pathname: 'categories'
      },
      {
        name: "Products",
        icon: <MdProductionQuantityLimits size={20} />,
        routeName: "/products",
        togglerName: '/products',
        pathname: 'products'
      },
      // {
      //   name: "Payments",
      //   icon: <MdPayments size={20} />,
      //   routeName: "/payments",
      //   togglerName: '/payments',
      //   pathname: 'payments'
      // },
      {
        name: "User Management",
        icon: <RiAdminFill size={20} />,
        routeName: "/user/management",
        togglerName: '/user/management',
        pathname: 'usermanagement'
      },
      {
        name: "Banners",
        icon: <FaTable size={20} />,
        routeName: "/banner",
        togglerName: '/banner',
        pathname: 'banner'
      },
      //  {
      //   name: "Reports",
      //   icon: <MdPayments size={20} />,
      //   routeName: "/reports",
      //   togglerName: '/reports',
      //   pathname: 'reports'
      // },
    ],
    3 : [
      {
        name: "Orders",
        icon: <RiFileList3Fill size={20} />,
        routeName: "/assigned/orders",
        togglerName: "/assigned/orders",
        pathname: 'assigned',
      }
    ],
      get : function(roleId) {
        if(this[roleId])
            return this[roleId]
        else return []
    }
}

// let roleBasedLinks = {
//     1 : ['profile','userManagement'],
//     // 5 : ['profile',"reports",'application'],
//     3 : ['profile',"reports",'application'],
//     "home":['profile','home',"settings"],
//     'default' : ['profile','dashboard','application','reports'],
//     get : function(roleId) {
//       // console.log(roleId,":" ,this[roleId])
//         if(this[roleId])
//             return this[roleId]
//         else return this['default']
//     }
// }
 

export const getLinksBasedOnRoleId = (roleId=1) => {
  let path = history.location.pathname.split('/').pop()
  // console.log(roleId,"*************")
    // let links = roleBasedLinks.get(roleId)
    // console.log(links,"links")
    // let filteredLink = TotalLinks.filter(data => links.includes(data.activeFilterName))
    let filteredLinks
    if(roleBasedAllLinks.get(roleId).length){
      
        // let CADApplicationIndex = filteredLink.findIndex(data => data.activeFilterName === 'application')
        // if(CADApplicationIndex >= 0){
          filteredLinks = roleBasedAllLinks.get(roleId)
          let checkPathInLinks = filteredLinks.findIndex(data => data.pathname == path)
          // if(checkPathInLinks == -1)
          // filteredLink[CADApplicationIndex]['routeName'] = filteredLink[CADApplicationIndex].subContent[0].routeName
        // }
    }
    return filteredLinks
}

//END SIDEBAR