const routers = [
    {
        path: '/',
        redirect: '/auth',
        auth: false
    },
    {
        component: 'StaticLayout',
        path: '/auth',
        auth: false,
        exact: false,
        redirect: '/auth/login',
        childrens: [
            {
                component: 'Login',
                path: '/login',
                auth: false,
                exact: true
            },
            // {
            //     component: 'ForgetPwd',
            //     path: '/forgot-password',
            //     auth: false,
            //     exact: true
            // },
            // {
            //     component: 'Register',
            //     path: '/register',
            //     auth: false,
            //     exact: true
            // },
            // {
            //     component: 'SetupNewPassword',
            //     path: '/setnewpassword',
            //     auth: false,
            //     exact: true
            // },
            // {
            //     component: 'Recoverpw',
            //     path: '/recoverpw',
            //     auth: false,
            //     exact: true
            // },
            // {
            //     component: 'Pages404',
            //     path: "/404",
            //     auth: false,
            //     exact: true
            // },
            // {
            //     component: 'Pages500',
            //     path: "/500",
            //     auth: false,
            //     exact: true
            // },
            // {
            //     component: 'LockScreen',
            //     path: "/lockscreen",
            //     auth: false,
            //     exact: true
            // }
        ]
    },
    {
        component: 'AdminLayout',
        path: '/staff',
        auth: true,
        exact: false,
        childrens: [
            {
                component: 'UserProfile',
                path: "/profile",
                auth: false,
                exact: true
            },
            {
                component: 'Dashboard',
                path: '/dashboard',
                auth: true,
                exact: true
            },
            {
                component: 'Orders',
                path: '/orders',
                auth: true,
                exact: true
            },
            {
                component: 'Customers',
                path: '/customers',
                auth: true,
                exact: true
            },
            {
                component: 'Categories',
                path: '/categories',
                auth: true,
                exact: true
            },
            {
                component: 'Products',
                path: '/products',
                auth: true,
                exact: true
            },
            {
                component: 'CreateProduct',
                path: '/products/create/product',
                auth: true,
                exact: true
            },
            {
                component: 'CreateProduct',
                path: '/products/create/product/:id',
                auth: true,
                exact: true
            },
            {
                component: 'Payments',
                path: '/payments',
                auth: true,
                exact: true
            },
            {
                component: 'UserManagement',
                path: '/user/management',
                auth: true,
                exact: true
            },
            {
                component: 'CreateUser',
                path: '/user/management/create',
                auth: true,
                exact: true
            },
            {
                component: 'CreateUser',
                path: '/user/management/create/:id',
                auth: true,
                exact: true
            },
            {
                component: 'Banners',
                path: '/banner',
                auth: true,
                exact: true
            },
            {
                component: 'Reports',
                path: '/reports',
                auth: true,
                exact: true
            },
            {
                component: 'DeliveryPartnerAssignedOrders',
                path: '/assigned/orders',
                auth: true,
                exact: true
            },
        ]
    }
    
]

export default routers 
