import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';

import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormikFormInput from 'components/FormInput/formikForInput';

import BackBtn from 'components/Button/backBtn';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { API } from 'service/api';
import { API_BOOK } from 'service/endpoints';
// Custom
import * as orderAction from 'actions/order';

const EditOrderManagementFunc = (props) => {
  const defaultOrderData = {
    status: '',
    orderAmount: '',
    deliveryCharge: '',
    totalAmount: '',
    cancelReason: '',
  };
  const {
    match: { params },
  } = props;

  const [isEdit, setEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [orderDetail, setDetail] = useState({
    status: null,
    orderAmount: null,
    deliveryCharge: null,
    totalAmount: null,
    cancelReason: null,
  });

  const [queryPayload, setQueryPayload] = useState({
    search: '',
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSuboption, setSelectedSuboption] = useState("");


  useEffect(() => {
    if (orderDetail['status'] !== selectedSuboption) {
      setSelectedSuboption(orderDetail['status']);
    }
    getApis();
  }, [orderDetail,selectedSuboption]);

  const getApis = async () => {
    if (params && params.id && !isEdit) {
      await getSingleOrderDetail(params.id);
    }
  };
  
  const getSingleOrderDetail = async (id) => {
    try {
      let { GET_ORDER_BY_ID_API } = API_BOOK.MAIN_APP.ORDER;
      const requestPayload = {
        ...GET_ORDER_BY_ID_API,
        url: GET_ORDER_BY_ID_API.url + `/${id}`,
      };

      const res = await API(requestPayload);
      if (res && !_.isEmpty(res.data)) {
        setEdit(true);
        setDetail({
          ...orderDetail,
          id: res.data.id,
          userName: res.data.customerDetails.userName,
          status: res.data.status,
          orderAmount: res.data.orderAmount,
          deliveryCharge: res.data.deliveryCharge,
          totalAmount: res.data.totalAmount,
          cancelReason: res.data.cancelReason,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getOrderList = async (queryPayload) => {
    await props.getOrderList(queryPayload);
  };

  const filterList = (payload) => {
    setQueryPayload({ ...queryPayload, ...payload });
    getOrderList(payload);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: (orderDetail && orderDetail.status) || '',
    },
    validationSchema: Yup.object({
      status: Yup.string().required('Choose status'),
    }),
    onSubmit: async (values) => {
      let payload = {
        status: values.status,
        
      };
      if (isEdit) {
        payload['id'] = orderDetail.id;
        await props.updateOrder(orderDetail.id, payload);
      }

      validation.resetForm();
      setDetail({ ...orderDetail, ...defaultOrderData });
    },
  });

  return (
    <div className='page-content'>
      <Container fluid>
        <>
          <Row>
            <Col xs='12'>
              <div className='page-title-box d-flex'>
                <BackBtn history={props.history} />
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardTitle className='h4 mb-4'>Edit Order Status</CardTitle>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col className='col-lg-12'>
                        <>
                          <Row>
                            <Col className='col-12' lg='3' md='3'>
                                <FormikFormInput
                                  placeholder='userName'
                                  name='userName'
                                  type='text'
                                  formGroupClassName='mb-3'
                                  label='User Name'
                                  labelClassName='col-form-label'
                                  value={orderDetail['userName']}
                                  validation={validation}
                                />
                              </Col>
                              <Col className='col-12' lg='3' md='3'>
                                <FormikFormInput
                                  placeholder=' orderAmount'
                                  name='orderAmount'
                                  type='text'
                                  formGroupClassName='mb-3'
                                  label='orderAmount'
                                  labelClassName='col-form-label'
                                  value={orderDetail['orderAmount']}
                                  validation={validation}
                                />
                              </Col>
                              <Col className='col-12' lg='3' md='3'>
                                <FormikFormInput
                                  placeholder='Delivery Charge'
                                  name='deliveryCharge'
                                  type='text'
                                  formGroupClassName='mb-3'
                                  label='Delivery Charge'
                                  labelClassName='col-form-label'
                                  value={orderDetail['deliveryCharge']}
                                  validation={validation}
                                />
                              </Col>

                              <Col className='col-12' lg='3' md='3'>
                                <FormikFormInput
                                  placeholder='Total Amount'
                                  name='totalAmount'
                                  type='text'
                                  formGroupClassName='mb-3'
                                  label='Total Amount'
                                  labelClassName='col-form-label'
                                  value={orderDetail['totalAmount']}
                                  validation={validation}
                                />
                              </Col>
                              {orderDetail['status']?.value === '5' && (
                                <Col className='col-12' lg='3' md='3'>
                                  <FormikFormInput
                                    placeholder='cancelReason'
                                    name='cancelReason'
                                    type='text'
                                    formGroupClassName='mb-3'
                                    label='Cancel Reason'
                                    labelClassName='col-form-label'
                                    value={orderDetail['ancelReason']}
                                    validation={validation}
                                  />
                                </Col>
                              )}
                          </Row>
                        </>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className='d-flex justify-content-end'>
                          <button type='submit' className='px-4 primary_btn'>
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      </Container>
    </div>
  );
};
const mapStateToProps = ({ order, loader }) => {
  return {
    order,
    loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(orderAction, dispatch);
};

const EditOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOrderManagementFunc);

export { EditOrder };
