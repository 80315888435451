import { admin } from 'service/actionType'
import { API } from 'service/api'
import {API_BOOK} from 'service/endpoints'
import {Toast} from 'helpers/Toast'

    
let {  ADMINS_LIST_API,CREATE_ADMIN_API,UPDATE_ADMIN_API,DELETE_ADMIN} = API_BOOK.MAIN_APP.ADMIN
let { 
    IS_USER_LIST_FETCHING,
    IS_USER_DETAIL_FETCHING,
     
    GET_ADMINS_LIST,
} = admin.ADMIN


export const getAdminList = (queryPayload) => async (dispatch, getState) => {
    try {
        
        dispatch({ type:IS_USER_LIST_FETCHING, payload:  true })
        let requestPayload = {
            ... ADMINS_LIST_API,
            params: {
                ...queryPayload
            }
        }
        let response = await API(requestPayload)
        if(response.status === 200){
            dispatch({ type: GET_ADMINS_LIST, payload: response.data})
            dispatch({type: IS_USER_LIST_FETCHING, payload:false})
        } else {
            Toast('error', response?.data?.message)
            dispatch({type: IS_USER_LIST_FETCHING, payload:false})
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message)
    }
}

export const createAdmin = (payload) => async (dispatch, getState) => {
    try {
        dispatch({type:IS_USER_DETAIL_FETCHING, payload:true})
        let requestPayload = {
            ...CREATE_ADMIN_API,
            data: payload
        }
        let response = await API(requestPayload)
        if(response.status === 201){
            dispatch({type:IS_USER_DETAIL_FETCHING, payload:false})
            Toast('success', "Created Successfully")
            return true
        } else {
            Toast('error', response?.data?.message)
            return false
        }
    } catch (error) {
        Toast('error', error?.response?.data?.message)
        return false
    }
}
export const updateAdmin = (payload,id) => async (dispatch, getState) => {
    try {
        dispatch({type: IS_USER_DETAIL_FETCHING, payload:true})
        let requestPayload = {
            ...UPDATE_ADMIN_API,
            url: `${UPDATE_ADMIN_API.url}/${id}`,
            data: payload
        }
        let response = await API(requestPayload)
        if(response.status === 200){
            dispatch({type: IS_USER_DETAIL_FETCHING, payload:false})
            Toast('success', "Updated Successfully")
            return true
        } else {
            dispatch({type: IS_USER_DETAIL_FETCHING, payload:false})
            Toast('error', response?.data?.message)
            return false
        }
    } catch (error) {
        dispatch({type: IS_USER_DETAIL_FETCHING, payload:false})
        Toast('error', error?.response?.data?.message)
        return false
    }
}

// export const deleteAdmin= (id) => async (dispatch, getState) => {
//     try {
//             dispatch({type: IS_USER_DETAIL_FETCHING, payload:false})
//         let requestPayload = {
//             ...DELETE_ADMIN,
//             url: `${DELETE_ADMIN.url}/status/${id}`,
//         }
//         let response = await API(requestPayload)
//         if(response.status == 201){
//             dispatch({type: IS_USER_DETAIL_FETCHING, payload:false})
//             Toast('success', "Successfully Updated")
//         } else {
//             Toast('error', response?.data?.message)
//             dispatch({type: IS_USER_DETAIL_FETCHING, payload:false})
//         }
//     } catch (error) {
//         dispatch({type: IS_USER_DETAIL_FETCHING, payload:false})
//         Toast('error', error?.response?.data?.message)
//     }
// }

  