import PropTypes from 'prop-types';
import React, { useState } from "react";
import { connect } from "react-redux";
// Reactstrap
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from 'actions/layout'

const Header = ({ title }) => {

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("open-sidebar");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
            <div className="d-flex align-items-center">
              <button
                type="button"
                onClick={() => {
                  tToggle();
                }}
                className="btn btn-sm px-3 font-size-16 header-item "
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
              <div className="position-relative" style={{ marginTop: '7px' }}>
                <h4 className="page-title">{title}</h4>
              </div>
            </div>
          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};
Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};
const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};
export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})((Header));