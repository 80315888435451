import { history } from 'helpers/utils'
import { axios } from "./axios";
import {Toast} from 'helpers/Toast'

export const API = async ({ url, method, headers=null , responseType, baseUrlType, data, params }) => {
    let axiosRequestObject = {
      method,
      headers,
      url,
      baseURL: 'https://vegride.co.in',
      data,
      responseType:responseType,
      params
    };
  
    let request = await axios(axiosRequestObject)
    .then(handleSuccessRequest)
    .catch(handleErrorRequest);

    return request;
};

const handleErrorRequest = (err) => {
  if(!err.response) return Promise.reject()
  if(err.response?.status === 401){
    if(!localStorage.getItem('authorization')){
      history.replace('/auth/login');
    
    }
    localStorage.clear()
    Toast({ type: 'error', message: 'Unauthorized!!'})

  }
  if (err.response) return err.response;
  else
    return {
      status: "500",
      data: {
        message: "Internal server error!!",
      },
    };
};

const handleSuccessRequest = (response) => (
  {
    status: response.status,
    data: response.data.data,
});