import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Container,
} from "reactstrap"
import axios from 'axios';
import './banners.scss'
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Breadcrumb
import Header from 'components/navs/Header';
import * as bannerAction from 'actions/banner';
import FormUpload from 'components/Uploadfile/FormDropzoneUpload';

const BannerManagementFunc = (props) => {
  const [bannerData, setBannerList] = useState([
    {
      bannerName: 'Banner 1',
      bannerUrl: '',
      id: 1,
      isEdit: false
    },
    {
      bannerName: 'Banner 2',
      bannerUrl: '',
      id: 2,
      isEdit: false
    },
    {
      bannerName: 'Banner 3',
      bannerUrl: '',
      id: 3,
      isEdit: false
    }
  ]);

  const dispatch = useDispatch();

  const { bannerList } = useSelector((state) => ({
    bannerList: state.banner.bannerList,
  }));

  useEffect(() => {
    getBannerList();
  }, [dispatch]);

  const getBannerList = async () => {
    await props.getBannerList();
  };

  useEffect(() => {
    if (bannerList && bannerList.data && bannerList.data.length > 0) {
      bannerData.map(ban => {
        let getBan = bannerList.data.find(baner => ban.bannerName == baner.bannerName)
        if (getBan) {
          ban.bannerUrl = getBan.bannerUrl
          ban.id = getBan.id
          ban.isEdit = true
        }
      })
      setBannerList(bannerData);
    }
  }, [bannerList]);

  async function handleUploadImage(value, index) {
    let formData = new FormData();
    formData.append('image', value);
    await axios
      .post('http://api.kasapp.in/api/admin/upload', formData, {
        headers: {
          authorization: localStorage.getItem('authorization')
        }
      })
      .then((res) => {
        if (res.status === 200) {
          const newBannerList = [...bannerData];
          newBannerList[index]["bannerUrl"] = res?.data?.data?.imageUrl
          setBannerList(newBannerList);
        }
      })
      .catch((err) => {
      });
  }

  const updateBanner = (item) => {
    if (item.bannerUrl != '') {
      if (item.isEdit) props.updateBanner(item)
      else props.createBanner(item)
    }
  }
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
        body.classList.remove("sidebar-enable");
    } 

  }
  return (
    <div className='page-content'>
        <Container fluid={true}>
          <Row>
            {bannerData.map((item, index) => (
              <Col className='col-lg-4 col-md-4 col-12'>
                <FormUpload
                  title={item.bannerName}
                  preview={item.bannerUrl}
                  handleFileUpload={(file) => handleUploadImage(file, index)}
                />
                <div className='d-flex align-items-center justify-content-center my-3'>
                  <button
                    type='submit'
                    className={`px-4 ${item.bannerUrl == '' ? 'secondary_btn' : 'primary_btn'}`}
                    onClick={() => updateBanner(item)}
                    disabled={item.bannerUrl == '' ? true : false}
                  >
                    Save {item.bannerName}
                  </button>
                </div>

              </Col>
            ))}
            <div style={{ textAlign: 'right' }} className='mt-4'>
              <div className="text-muted">
                ( Note : Upload banner with a <strong>width of 450px</strong> and a <strong>height of 150px</strong> )
              </div>
            </div>

          </Row>

        </Container>
    </div>
  );
}
const mapStateToProps = ({ banner, loader }) => {
  return {
    banner,
    loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(bannerAction, dispatch);
};

const Banners = connect(
  mapStateToProps,
  mapDispatchToProps
)(BannerManagementFunc);

export { Banners }