import { banner } from 'service/actionType'
// import { inventory, main } from '../../service/actionType'
const initialState = {
    bannerList: {
        data: [],
        pageMetaData: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        }
    }
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case banner.BANNER.BANNER_LIST:
           
            return {
                ...state,
                bannerList: {
                    data: payload,
                    pageMetaData: payload.pageMetaData
                }
               
            }
           
        default:
            
            return state
    }

}