import { formatDate } from "./format_helper";
import _ from 'lodash';

export const TopCustomersTable =  [
  {
    dataField: 'S.No',
    text: 'S.No.',
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }
  },
  {
    text: "Customer Name",
    dataField: 'userName',
    sort: true
  },
  {
    text: "Customer Mobile Number",
    dataField: 'mobile'
  },
  {
    text: "Number Of Orders",
    dataField: 'totalOrders'
  }
]
export const OrdersTableList =  [
  {
    dataField: 'S.No',
    text: 'S.No.',
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }
  },
  {
    text: "Order Id",
    dataField: "id",
    headerStyle: {textAlign: 'center'},
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return `#${cell.slice(-6)}`
    }
  },
  {
    text: "Customer Name",
    dataField: "customerDetails.userName",
    headerStyle: {textAlign: 'center'},
    
  },
  {
    text: "Order Amount",
    headerStyle: {textAlign: 'center'},
    dataField: "orderAmount",
  },
  {
    text:'Delivery Charge',
    headerStyle: {textAlign: 'center'},
    dataField: "deliveryCharge",
  },
  {
    text: "Total Amount",
    headerStyle: {textAlign: 'center'},
    dataField: "totalAmount",
  },
  {
    text: "Order Status",
    headerStyle: {textAlign: 'center'},
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return row.status === 4 ? <p className="order_status" style={{backgroundColor: '#069906'}}>DELIVERED</p> : 
      row.status === 3 ? <p className="order_status" style={{backgroundColor: "#0707b3"}}>ON ROAD</p> : 
      row.status === 2 ? <p className="order_status" style={{backgroundColor: "#00b2ff"}}>CONFIRMED</p> : 
      row.status === 5 ? <p className="order_status" style={{backgroundColor: "#ec1e24"}}>CANCELLED</p> :
      row.status === 1 ? <p className="order_status" style={{backgroundColor: "#f37c2c", width: '120px !important'}}>ORDER CREATED</p> :
      row.status === 6 ? <p className="order_status" style={{backgroundColor: "#ec1e24"}}>RETURN ORDER</p>  : '-'
      
    }
  },
  {
    text: "Payment Method",
    headerStyle: {textAlign: 'center'},
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return row?.paymentMode === 1 ? <p className="cash">Cash</p> : row?.status === 2 ? <p className="online">Online</p> : '-'
    }
  },
  {
    text: "Payment Status",
    headerStyle: {textAlign: 'center'},
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return row.paymentStatus === 4 ? <p className="notpaid">Not Paid</p> : 
      row.paymentStatus === 3 ? <p className="paid">ON ROAD</p> : 
      row.paymentStatus === 6 ? <p >--</p>  : '-'
    }
  },
  {
    text: "Ordered At",
    dataField: "createdAt",
    formatter: (cellContent, row, rowIndex) => {
      return formatDate(row.createdAt, "YYYY-MM-DD hh:mm:ss a")
    }
  },
]

export const CategoryTableColumns = [
  {
    dataField: 'S.No',
    text: 'S.No.',
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    },
  },
  {
    dataField: 'categoryName', 
    text: 'Category Name',
    sort: true,
   
   
  },
  // {
  //   text: 'Category Name In Tamil',
  //   headerStyle: { textAlign: 'center' },
  //   dataField: 'categoryNameTamil',
    
  // },
  {
    text: "category Image",
    dataField: "categoryImage",
    formatter : (cellContent, row, rowIndex) => {
      return <img src={cellContent} style={{width: '50px', height: '50px', objectFit: 'contain'}} alt="image" />
    }
  },
  {
    text: 'Status',
    dataField: 'isAvailable',
    formatter: (cellContent, row, rowIndex) => {
      return (
        <div
          className={`status_text ${
            row.isAvailable === 1 ? 'primary_bg' : 'secondary_bg'
          }`}
        >
          <p>{`${row.isAvailable === 1 ? 'Active' : "Inactive"}`}</p>
        </div>
      );
    },
  },
  {
    text: 'Created At',
    dataField: 'createdAt',
    formatter: (cellContent, row, rowIndex) => {
      return formatDate(row.createdAt, 'YYYY-MM-DD hh:mm:ss a');
    },
  },

]



export const ProductTableColumns =  [
  
  {
    dataField: 'S.No',
    text: 'S.No.',
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    },
  },

 
  {
    text: "Product Name",
    dataField: "productName",
    formatter: (cellContent, row, rowIndex) => {
      return row?.productName + " / " + row?.productNameTamil
    },
    sort: true
  },

  {
    dataField: 'categoryId',
    text: 'Category Name',
    sort: true,
    formatter: (cell, row) => {
      return row?.categoryDetails?.categoryName;
    },
  },
  
  {
    text: 'Unit',
    formatter: (cellContent, row, rowIndex) => {
      return row.quantity + " " + row.unitType
    }
  },
  {
    text: "Price",
    dataField: "finalPrice",
    sort: true
  },
  {
    text: "Striked Price",
    dataField: "price",
    sort: true
  },
  
  {
    text: "Image",
    dataField: "productImage",
    formatter : (cellContent, row, rowIndex) => {
      return <img src={cellContent} style={{width: '70px', height: '70px', objectFit: 'contain'}} alt="image"  />
    }
  },
  {
    text: "Created At",
    dataField: "createdAt",
    formatter: (cellContent, row, rowIndex) => {
      return formatDate(row.createdAt, "YYYY-MM-DD hh:mm:ss a")
    }
  },
  {
    text: 'Status',
    dataField: 'status',
    formatter: (cellContent, row, rowIndex) => {
      return (
        <div
          className={`status_text ${
            row?.isAvailable === 1 ? 'primary_bg' : 'secondary_bg'
          }`}
        >
          <p>{`${row?.isAvailable === 1 ? 'Active' : 'Inactive'}`}</p>
        </div>
      );
    },
  },
  
]

export const CustomerTableColumns = [
{
  dataField: 'S.No',
  text: 'S.No.',
  formatter: (cell, row, rowIndex, formatExtraData) => {
    return rowIndex + 1;
  }
},
{
  text: "Customer Name",
  dataField: "userName",
},
{
  text: "Mobile Number",
  dataField: "mobile",
},
{
  text: "Email Id",
  dataField: "email"
},
{
  text: "Customer Address",
  dataField: "addressDetails",
  formatter: (cellContent, row, rowIndex) => {
    let address = row?.addressDetails[0]
    return <p>
      {address?.addressLine1} {address?.addressLine2}, {address?.landmark}, {address?.postalCode}
    </p> 
  }
},


{
  text: 'Status',
  dataField: 'status',
  formatter: (cellContent, row, rowIndex) => {
    return (
      <div
        className={`status_text ${
          cellContent === 1 ? 'primary_bg' : 'secondary_bg'
        }`}
      >
        <p>{`${cellContent === 1 ? 'Active' : 'Inactive'}`}</p>
      </div>
    );
  },
},
{
  text: "Joined On",
  dataField: "createdAt",
  headerStyle: {textAlign: 'center'},
  formatter: (cellContent, row, rowIndex) => {
    return formatDate(row.createdAt, "YYYY-MM-DD hh:mm:ss a")
  }
},
]

export const DeliveredTableColumns = [
  {
    dataField: 'S.No',
    text: 'S.No.',
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }
  },
  {
    text: "Customer Name",
    dataField: 'customerDetails.userName' 
  },
  {
    text: "Customer Mobile Number",
    dataField: 'customerDetails.mobile' 
  },
  {
    text: "Order Id",
    dataField: 'id'
  },
  {
    text: "Total",
    dataField: 'totalAmount'
  },
  // {
  //   text: "Delivery Charge",
  //   dataField: 'deliveryCharge'
  // },

  {
    text: "Customer Address",
    dataField: "orderAddress",
    formatter: (cellContent, row, rowIndex) => {
      let address = row?.orderAddress
      return <p style={{marginTop: '12px'}}>
        {address?.addressLine1} {address?.addressLine2}, {address?.landmark}, {address?.postalCode}
      </p> 
    }
  },
  {
    text: "Status",
    headerStyle: {textAlign: 'center'},
    formatter: (cell, row, rowIndex, formatExtraData) => {
      if (row.status === "4") {
        return <p className="order_status" style={{backgroundColor: '#069906'}}>DELIVERED</p>;
      } else {
        return null; 
      }
    }
  },
  
  {
    text: "Last Updated At",
    formatter: (cellContent, row, rowIndex) => {
      return formatDate(row.createdAt, "YYYY-MM-DD hh:mm:ss a")
    }
  },
  
]


export const CustomerPaymentTableList = [
{
  dataField: 'S.No',
  text: 'S.No.',
  formatter: (cell, row, rowIndex, formatExtraData) => {
    return rowIndex + 1;
  }
},
{
  text: "Payment Id",
  dataField: "customerPaymentId",
},
{
  text: "Customer ID",
  dataField: "userId"
},
{
  text: "Order ID",
  dataField: "orderId"
},
{
  text: "Customer Name",
  dataField: "userName"
},
{
  text: "Mobile Number",
  dataField: "userMobileNumber"
},
{
  text: "Total Amount",
  dataField: "totalAmount"
},
{
  text: "payment Type",
  dataField: "paymentType"
},
{
  text: "status",
  dataField: "status",
  formatter: (cell, row, rowIndex, formatExtraData) => {
    return row.status === "PAID" ? <p className="order_status" style={{backgroundColor: '#069906'}}>Paid</p> : 
    <p className="order_status" style={{ backgroundColor: "#ec1e24", width: '120px !important'}}>Not Paid</p>  
  },
},
{
  text: "Created At",
  dataField: "createdAt",
  formatter: (cellContent, row, rowIndex) => {
    return formatDate(row.createdAt, "YYYY-MM-DD hh:mm:ss a")
  }
},

]

export const RevenueListTable = [
{
  dataField: 'shopId',
  text: 'Shop ID',
  formatter: (cell, row, rowIndex, formatExtraData) => {
    return rowIndex + 1;
  }
},
{
  text: "Shop Name",
  dataField: "shopName",
},
{
  text: "Mobile Number",
  dataField: "shopMobileNumber"
},
{
  text: "Total Revenue Generated",
  dataField: "totalRevenueGenerated",
  formatter: (cellContent, row, rowIndex) => {
    return parseInt(row.totalRevenueGenerated)
  }
},
{
  text: "Total Delivery Charge",
  dataField: "totalDeliveryCharge"
},
{
  text: "Final Revenue Generated",
  dataField: "totalAmount",
  formatter: (cellContent, row, rowIndex) => {
    return parseInt(row.totalRevenueGenerated) -  parseInt(row.totalDeliveryCharge)
  }
}

]


export const AdminsTableList = [
{
  dataField: 'S.No',
  text: 'S.No.',
  formatter: (cell, row, rowIndex, formatExtraData) => {
    return rowIndex + 1;
  }
},

{ 
  dataField: 'userName', 
  text: 'Name', 
  sort: true 
},
{ 
  dataField: 'mobile', 
  text: 'Mobile Number' 
},
{ 
  dataField: 'email', 
  text: 'Email' 
},
{
  text: "Role",
  dataField: "roleId",
  formatter: (cellContent, row, rowIndex) => {
    return row.roleId == 1 ? 'Super Admin' : row.roleId == 2 ? 'Admin' : row.roleId == 3 ? 'Delivery Partner' : ''
  }
},
{
  text: 'Status',
  dataField: 'status',
  formatter: (cellContent, row, rowIndex) => {
    return (
      <div
        className={`status_text ${
          cellContent === 1 ? 'primary_bg' : 'secondary_bg'
        }`}
      >
        <p>{`${cellContent === 1 ? 'Active' : 'Inactive'}`}</p>
      </div>
    );
  },
},
{
  text: 'Created At',
  dataField: 'createdAt',
  formatter: (cellContent, row, rowIndex) => {
    return formatDate(row.createdAt, 'YYYY-MM-DD hh:mm:ss a');
  },
},
]


export const DeliveryBoyPaymentsTable = {
columns : [
  {
    header: "Delivery Boy ID",
    accessor: "userMasterId"
  },
  {
    header: "Delivery Boy Name",
    accessor: "name"
  },
  {
    header: "Mobile Number",
    accessor: "mobileNumber"
  },
  {
    header: "Total Amount",
    accessor: "totalDeliveryCharge"
  },
  {
    header: "Amount Sent",
    accessor: "amountSent"
  },
  {
    header: "Pending Amount To Be Sent",
    accessor: "pendingAmountToBeSent"
  }
],
rows: []
}

export const BannerTableList = [

{
  text: "Banner type",
  dataField: "type",
  headerClasses: "columnHeader",
  sort: true,
  formatter: (cellContent, row, rowIndex) => {
    return row.type == '1' ? "HOME BANNER" : row.type == "2" ? "FSE BANNER" : "SUBSCRIPTION BANNER"
  },
},
{
  text: "Banner",
  dataField: "banners",
  headerClasses: "columnHeader",
  sort: true,
  formatter: (cellContent, row, rowIndex) => {
    return cellContent.map(cell => <img src={cell} style={{width: '100px',marginRight:"10px"}} alt="image"  /> ) 
  },
},
{
  text: "Created At",
  dataField: "createdAt",
  headerClasses: "columnHeader",
  sort: true,
  formatter: (cellContent, row, rowIndex) => {
    return formatDate(row.createdAt, "YYYY-MM-DD hh:mm:ss a")
  }
},
]