import {dashboard} from 'service/actionType'

const initialState = {
    dashboardCountsAndTopCustomers : {
        topCustomers: [],
        counts: {
            totalOrders: 0,
            totalOpenOrder: 0,
            totalCompletedOrder: 0,
            totalCancelledOrder: 0,
            totalCategories: 0,
            totalCustomers: 0,
            totalProduct: 0
        }
    },
    yearlyOrdersStats: {
        revenue: [],
        orders: []
    },
}

export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
       
        case dashboard.TOP_CUSTOMERS_AND_DASHBOARD_COUNTS:
            return {
                ...state,
                dashboardCountsAndTopCustomers: {
                    topCustomers: payload.topCustomers,
                    counts: {
                        ...state.dashboardCountsAndTopCustomers.counts,
                        ...payload.ordersAnalytics,
                        totalCategories: payload?.totalCategories?.totalCategories,
                        totalCustomers: payload?.totalCustomer?.totalCustomers,
                        totalProduct: payload?.totalProduct?.totalProduct
                    }
                }
            }

            case dashboard.YEARLY_ORDER_STATS:
                return {
                    ...state,
                    yearlyOrdersStats: payload
                }
        default:
            return state
    }

}