import React, { useState } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from 'reactstrap';
//redux
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

import logo from 'assets/images/logo.png';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AiFillEye,AiFillEyeInvisible } from 'react-icons/ai'
// actions
import { API, API_BOOK } from 'service';
import { Toast } from 'helpers/Toast';

// import images
import profile from 'assets/images/profile-img.png';
import image from 'assets/images/image.jpg';
import { login } from 'service/actionType';
import './login.scss';

const { SESSION_API } = API_BOOK.ADMIN_MANAGEMENT;

const LoginFunc = (props) => {
  const [passwordIc , setPasswordic] = useState(true)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: async (values) => {
      // dispatch(loginUser(values, props.history));
      try {
        let requestPayload = {
          ...SESSION_API.LOGIN_API,
          data: values,
        };
        let response = await API(requestPayload);
        if (response.status === 200) {
          props.dispatch({ type: login.ADMIN_LOGIN, payload: response.data });
          localStorage.setItem('authorization', response.data.authToken);
          let pathname = `/staff/dashboard`;
          if (response.data.roleId === 1 || response.data.roleId === 2) {
            pathname = `/staff/dashboard`
          } else {
            pathname = `/staff/assigned/orders`
          }
          props.history.push({
            pathname,
          });
        } else {
          Toast('error', response?.data?.message);
        }
      } catch (error) {
        Toast('error', error?.response?.data?.message);
      }
    },
  });

  return (
    <React.Fragment>
      <div className='account-pages my-5 pt-sm-5'>
        <Container style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
          <Row style={{ minHeight: '500px' }}>
            <Col xs={12} md={6} className='order-md-1 d-none d-md-block' >
              <img src={image} alt='' className='img-fluid my-image' />
            </Col>
            <Col xs={12} md={6} className='order-md-2'>
              <div style={{ marginTop: '50px', marginRight: '30px' }} className='bg-horizontal'>
                <div className='d-flex align-items-center justify-content-center justify-content-md-start text-primary p-4'>
                  <div className='avatar-md profile-user-wid mb-4 me-3 me-md-0'>
                    <span className='avatar-title rounded-circle bg-light' style={{ marginTop: '15px' }}>
                      <img src={logo} alt='' height='50' />
                    </span>
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    <h5 className='text-primary'>Welcome Back!</h5>
                    <p>Sign in to continue to vegride</p>
                  </div>
                </div>

                <CardBody className='pt-0'>
                  <div className='p-2'>
                    <Form
                      className='form-horizontal'
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className='mb-3'>
                        <Label className='form-label'>Email</Label>
                        <Input
                          name='email'
                          className='form-control'
                          placeholder='Enter email'
                          type='email'
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className='mb-3'>
                        <Label className='form-label'>Password</Label>
                        <div className='position-relative'>
                          <Input
                            name='password'
                            value={validation.values.password || ''}
                            type={passwordIc ? 'password' : 'text' }
                            placeholder='Enter Password'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                                validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <div className='password_ic'>
                            {passwordIc ? 
                              <AiFillEyeInvisible 
                                size={20} 
                                onClick={() => setPasswordic(false)}/> 
                                : 
                              <AiFillEye 
                                size={20}
                                onClick={() => setPasswordic(true)}
                              /> 
                            }
                          </div>
                        </div>
                        {validation.touched.password &&
                          validation.errors.password ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className='mt-4 d-grid'>
                        <button
                          className='btn btn-primary btn-block'
                          type='submit'
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>



  );
};

const mapStateToProps = ({ profile, loader }) => {
  return {
    profile,
    loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginFunc);

export { Login };
